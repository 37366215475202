import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiDislikeLine } from "react-icons/ri";
import { CiHeart } from "react-icons/ci";

import styled from 'styled-components';
import { removeLike, addLike, userLoading } from '../../../redux/actions';
import { fadeIn } from '../../../helpers/styleUtil';
import { useInView } from 'react-intersection-observer';

const ViewContainer = styled.div`
  margin-top: 20px;
  font-size: 18px;
  width: 90%;
  height: 65vh;
  overflow-y: auto;
  margin: auto;
  animation: ${fadeIn} 0.6s ease-out forwards;
  font-family: "Outfit", sans-serif;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
`;

const ImageLink = styled.div`
  display: block;
  cursor: pointer;
    width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.01);
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 95%;
  margin: auto;
  margin-top: 20px;
`;

const FitItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const FitName = styled.p`
  font-size: 13px;
  font-family: "Outfit", sans-serif;
  letter-spacing: 0.05em;
  margin: 10px;
  color: black;
  margin-left: 0;
`;

const LikeButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${props => props.liked ? 'red' : 'white'};
  cursor: pointer;
  font-size: 24px;
`;

const LikeCounter = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const LikeList = styled.div`
  background: white;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50px;
  left: 15px;
  z-index: 10;
`;


const FitsView = () => {
  const dispatch = useDispatch();
  const discovery = useSelector((state) => state.discovery);
  const user = useSelector((state) => state.user);
  const isMobileView = useSelector(state => state.user.isMobileView);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [likeListVisible, setLikeListVisible] = useState(null); // State to track visible like list

  const { email: currentUserEmail, name } = user;
  const fetchMoreUsers = useCallback(() => {
    if (hasMore && discovery.status !== 'loading') {
      // dispatch(fetchDiscoveryUsers(page, 'Fits'));
    }
  }, [hasMore, discovery.status, page, dispatch]);

  useEffect(() => {
    fetchMoreUsers();
  }, [fetchMoreUsers]);

  useEffect(() => {
    if (discovery.status === 'loading') {
      dispatch(userLoading(true));
    } else {
      dispatch(userLoading(false));
    }
  }, [discovery.status, dispatch]);

  useEffect(() => {
    if (discovery.users.length < page * 30) {
      setHasMore(false);
    }
  }, [discovery.users, page]);

  const handleScroll = useCallback(() => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 500 && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleLike = (fitId, fitOwnerEmail) => {
    const fit = discovery.users
      .flatMap(user => user.fits)
      .find(fit => fit.id === fitId);

    const alreadyLiked = fit.likes.some(like => like.email === currentUserEmail /* current user's email */); // Use the current user's email to check if they already liked the fit

    if (alreadyLiked) {
      dispatch(removeLike(fitOwnerEmail, fitId, currentUserEmail)); // Pass the fit owner's email and current user's email
    } else {
      dispatch(addLike(fitOwnerEmail, fitId, currentUserEmail, name)); // Pass the fit owner's email, current user's email, and current user's name
    }
  };

  const handleMouseEnter = (fitId) => {
    setLikeListVisible(fitId);
  };

  const handleMouseLeave = () => {
    setLikeListVisible(null);
  };

  const filteredFits = discovery.users
    .filter(user => user.settings?.privacy !== 'private' && user.fits)
    .flatMap(user => user.fits.map(fit => ({
      ...fit,
      userName: user.name,
      userEmail: user.email
    })))
    .sort((a, b) => new Date(b.listingDate) - new Date(a.listingDate));

  return (
    <ViewContainer>
      <GridContainer>
        {filteredFits.map(fit => {
          const [inViewRef, inView] = useInView({
            triggerOnce: true,
            rootMargin: '5px',
          });

          return (
            <FitItem key={fit.id} ref={isMobileView ? null : inViewRef}>
              {(isMobileView || inView) && (
                <>
                  <ImageContainer>
                    <ImageLink onClick={() => window.open(fit.shareableLink, '_blank', 'noopener,noreferrer')}>
                      <Image src={fit.images[0]} alt={fit?.name} />
                    </ImageLink>
                    {fit.likes.length >= 1 && (
                      <LikeCounter>
                        {fit.likes.length} <CiHeart style={{ marginLeft: '5px' }} onMouseEnter={() => handleMouseEnter(fit.id)}
                          onMouseLeave={handleMouseLeave} />
                      </LikeCounter>
                    )}
                    {user?.email &&
                      <LikeButton
                        liked={fit.likes.some(like => like.email === currentUserEmail)}
                        onClick={() => handleLike(fit.id, fit.userEmail)}
                      >
                        {fit.likes.some(like => like.email === currentUserEmail) ? <RiDislikeLine /> : <CiHeart />}
                      </LikeButton>}
                    {likeListVisible === fit.id && fit.likes.length > 0 && (
                      <LikeList>
                        {fit.likes.map((like, index) => (
                          <div key={index}>{like.author}</div>
                        ))}
                      </LikeList>
                    )}
                  </ImageContainer>
                  {fit.name && (
                    <FitName>
                      {fit.name}
                    </FitName>
                  )}
                </>
              )}
            </FitItem>
          );
        })}
      </GridContainer>

    </ViewContainer>
  );
};

export default FitsView;
