// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;

export const serverUrl = env !== 'production' ? 'http://localhost:3001' : 'https://lekondo-34db6b201efb.herokuapp.com';

export const appUrl = env !== 'production' ? 'http://localhost:3000' : 'https://www.lekondo.com';

export const isValidUrl = (url) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
};