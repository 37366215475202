import { serverUrl } from '../../constants/urls';
import { userVibe, updateVisitingUserVibe } from '../actions/index';

export const vibeCheckMiddleware = store => next => action => {
  const result = next(action); // Ensure the action is passed to the next middleware/reducer
  const currentState = store.getState(); // Get the updated state

  const isVisitingUser = currentState.visitingUserDetails && currentState.visitingUserDetails.isVisiting;
  const email = isVisitingUser ? currentState.visitingUserDetails.email : currentState.user.email;
  const vibe = isVisitingUser ? currentState.visitingUserDetails.vibe : currentState.user.vibe;

  const totalItems = isVisitingUser ? currentState.visitingUserDetails?.items?.length : currentState.user?.items?.length; // Assuming items is an array in the state

  if (action.type === 'SET_USER') {
    if (email && (!vibe?.semanticDescription)) {
      fetchVibeCheck(email, isVisitingUser, store);
    }
  }

  if (action.type === 'ADD_ITEM' || action.type === 'DELETE_ITEM') {
    if (email && (totalItems % 20 === 0 && totalItems > 19)) {
      fetchVibeCheck(email, isVisitingUser, store);
    }
  }

  return result;
};

const fetchVibeCheck = (email, isVisitingUser, store) => {
  fetch(`${serverUrl}/api/vibeCheck`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(data => {
          if (isVisitingUser) {
            store.dispatch(updateVisitingUserVibe(data));
          } else {
            store.dispatch(userVibe(data));
          }
          return data;
        });
      } else {
        throw new Error('Failed to fetch vibe');
      }
    })
    .then(vibeData => {
      return;
    })
    .catch(error => {
      console.error('Error during vibe check:', error);
    });
};
