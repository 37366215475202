import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import { Brand, Category } from './itemForm/ItemView';
import { serverUrl } from '../../constants/urls';
import { FaInfoCircle } from 'react-icons/fa';
import placeholderImage from '../../assets/img/monad.png';
import { toggleAttribute, toggleItemForm, setCurrentItem, userLoading } from '../../redux/actions';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column; /* Default to column for stacking */
  border: 1px solid #e6e6e6;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  background-image: ${props => {
    switch (props.status) {
      case 'Wishlist':
        return 'linear-gradient(45deg, #125c25, #125c25, 20px, transparent 10px, transparent 20px)';
      case 'Kondo':
        return 'linear-gradient(45deg, #800f0f, #800f0f 20px, transparent 10px, transparent 20px)';
      default:
        return 'white';
    }
  }}

`;

const ArchivedLabel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: ${props => props.status === 'Archive' ? 1 : 0};
`;

const BottomRightInfo = styled.div`
 margin-top: 25px;
  align-self: flex-end; /* Aligns this specific div to the top */
`

const BelowPictureInfo = styled.div`
 margin-top: 25px;
  align-self: flex-start;  
  /* Aligns this specific div to the top */
`


const ImageWrapper = styled.div`
  width: 100%; /* Full width */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover; /* Cover the entire width, keeping aspect ratio */
    object-position: top; /* Align image from the top */
  border-radius: 8px;
   opacity: ${props => {
    switch (props.status) {
      case 'Archive':
        return 0.7;
      default:
        return 1;
    }
  }}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 12px;
  left: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;

  ${ItemContainer}:hover & {
    opacity: 1;
  }
    @media (max-width: 768px) {
    opacity: 1;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the cross axis */
  width: 100%;
  margin-top: 10px;
`;

const BrandsList = styled.div`
float: right;
  text-align: right;
  margin: 3px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 5px; /* Space between brand and price */
  color: #333;
`;

const CategoryList = styled.div`
  margin: 3px;
  display: flex;
  float: right;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 5px; /* Space between brand and price */
  color: #333;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: bold;
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const RetailPrice = styled.span`
  font-size: 14px;
  color: #888;
  text-decoration: line-through;
`;

const Discount = styled.span`
  font-size: 14px;
  color: #e60023; /* Red color to indicate discount */
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;

  &:hover {
    color: #000;
  }
`;

const WebsiteLink = styled.a`
padding-top: 8px;
  display: inline-block;
  color: #000;
  font-size: 10px;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid #000;
  transition: color 0.3s ease, border-bottom 0.3s ease;

  &:hover {
    color: #555;
    border-bottom: 1px solid #555;
  }
`;

const CompareTrigger = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: #007bff;
  cursor: pointer;
  text-transform: uppercase;
  text-align: left;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const ItemName = styled.div`
  font-size: 16px;
  color: ${props => props.status === 'Archive' ? '#888' : '#000'}; /* Gray out text for archived items */
  text-decoration: ${props => props.status === 'Archive' ? 'line-through' : 'none'}; /* Strikethrough for archived items */
`;

// eslint-disable-next-line react/display-name
const Item = memo(({ numberOfItems, handleItemClick, className, item, onCompare }) => {
  const user = useSelector(state => state.user);
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const selectedAttributes = useSelector(state => state.inventory.selectedAttributes);

  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  });

  if (!item) {
    return null;
  }

  const handleCompare = useCallback(async () => {
    dispatch(userLoading(true));
    if (!user || !user.email) {
      alert('You need to be logged in to compare items.');
      return;
    }

    const comparisonContext = user && visitingUserDetails?.isVisiting ? 'purchase' : 'reassessment';
    try {
      const response = await fetch(`${serverUrl}/api/compareProduct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, item, comparisonContext }),
      });

      if (response.ok) {
        const data = await response.json();
        onCompare(item, data.insights);  // Trigger the comparison modal with data
      } else {
        console.error('Comparison failed');
      }
    } catch (error) {
      console.error('Error comparing product:', error);
    }
  }, [item, user, onCompare]);

  const handleEdit = useCallback(() => {
    dispatch(setCurrentItem(item));
    handleItemClick();
    dispatch(toggleItemForm(true));
  }, [dispatch, item, handleItemClick]);

  const handleBrandClick = (brand) => {
    dispatch(toggleAttribute({ type: 'brand', value: brand }));
  };

  // Function to dispatch filter by category
  const handleCategoryClick = (category) => {
    dispatch(toggleAttribute({ type: 'category', value: category }));
  };

  const isSelected = (type, value) => {
    return selectedAttributes.some(attr => attr.type === type && attr.value === value);
  };

  const images = item.images && item.images.length > 0 ? item.images : [placeholderImage];
  const firstImage = images[0];

  const retailPrice = item.retailPrice;
  const status = item.status;
  const discountPercentage = retailPrice ? Math.round(((retailPrice - item.price) / retailPrice) * 100) : null;

  return (
    <ItemContainer className={className} status={item.status}>
      <ArchivedLabel status={item.status}>Archived</ArchivedLabel> {/* Archive label */}
      <IconContainer>
        <IconButton onClick={handleEdit}>
          <FaInfoCircle />
        </IconButton>
      </IconContainer>
      <ImageWrapper ref={ref}>
        {inView && <ItemImage src={firstImage} alt={item.name} loading="lazy" status={item.status} />}
      </ImageWrapper>
      <ItemDetails>
        <BelowPictureInfo>
          {status === 'Kondo' &&
            <PriceContainer>
              <div type={item.retailPrice}>${item.price}</div>
              {item.retailPrice && (
                <>
                  <RetailPrice>{item.retailPrice}</RetailPrice>
                  <Discount>{discountPercentage}% off</Discount>
                </>
              )}
            </PriceContainer>
          }
          {user && numberOfItems > 20 && !user?.isMobileView && (
            <>
              <CompareTrigger onClick={handleCompare}>
                Contextualize
              </CompareTrigger>
            </>
          )}
          <ItemName status={item.status}>
            {item.name.length > 25 ? `${item.name.substring(0, 25)}...` : item.name}
          </ItemName>
          {item.source?.fromWebsiteUrl && (
            <WebsiteLink href={item.source.fromWebsiteUrl} target="_blank" rel="noopener noreferrer">
              {item.source.fromWebsiteUrl.length > 20 ? item.source.fromWebsiteUrl.substring(0, 20) + '...' : item.source.fromWebsiteUrl}
            </WebsiteLink>
          )}
        </BelowPictureInfo>
        <BottomRightInfo>
          <BrandsList>
            {Array.isArray(item.brands) && item.brands.map((brand, index) => (
              <Brand key={index} onClick={() => handleBrandClick(brand)} style={{ cursor: 'pointer' }}
                className={isSelected('brand', brand) ? 'selected' : ''}
              >
                {brand}
              </Brand>
            ))}
          </BrandsList>
          <CategoryList>
            {Array.isArray(item.categories) && item.categories.map((category, index) => (
              <Category key={index} onClick={() => handleCategoryClick(category)} style={{ cursor: 'pointer' }}
                className={isSelected('category', category) ? 'selected' : ''}
              >
                {category}
              </Category>))}
          </CategoryList>
        </BottomRightInfo>
      </ItemDetails>
    </ItemContainer>
  );
});

export default Item;
