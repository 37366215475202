// CollectionsView.js
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UserCard from './UserCard';
import { userLoading, fetchDiscoveryUsers } from '../../../redux/actions';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;
  height: 65vh;
  overflow-y: auto;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 801px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CollectionsView = ({ page, setPage, hasMore, setHasMore }) => {
  const dispatch = useDispatch();
  const discovery = useSelector((state) => state.discovery);
  const currentUser = useSelector((state) => state.user);

  const fetchMoreUsers = useCallback(() => {
    if (hasMore && discovery.status !== 'loading') {
      dispatch(fetchDiscoveryUsers(page));
    }
  }, [hasMore, discovery.status, page, dispatch]);

  useEffect(() => {
    fetchMoreUsers();
  }, [fetchMoreUsers]);

  useEffect(() => {
    if (discovery.status === 'loading') {
      dispatch(userLoading(true));
    } else {
      dispatch(userLoading(false));
    }
  }, [discovery.status, dispatch]);

  useEffect(() => {
    if (discovery.users.length < page * 50) {
      setHasMore(false);
    }
  }, [discovery.users, page]);

  const handleScroll = useCallback(() => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 500 && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const isAdmin = currentUser?.email === 'yengkt1@gmail.com' || false;

  const filteredUsers = isAdmin ? discovery.users : discovery.users
    .filter(user => user.email !== currentUser.email && (user.settings?.privacy !== 'private')
      && (user.itemCount || 0) >= 10) // Only include users with 10 or more items
    .sort((a, b) => (b.itemCount || 0) - (a.itemCount || 0));

  return (
    <Grid>
      {filteredUsers.map(user => (
        <UserCard key={user.id} user={user} />
      ))}
    </Grid>
  );
};

export default CollectionsView;
