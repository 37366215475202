import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import SearchResultCard from './SearchResultCard';

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // Adjust this for the card width
  gap: 10px;
`;

const SearchResults = ({ results, onItemAdded }) => {
    const isMobileView = useSelector(state => state.user.isMobileView);

    return (
        <ResultsGrid>
            {results && results
                .filter(result => result && result.name) // Filter out null, undefined, or items with no name
                .slice(0, isMobileView ? 2 : 3)
                .map((result, index) => (
                    <SearchResultCard key={index} result={result} onItemAdded={onItemAdded} />
                ))}
        </ResultsGrid>
    );
};

export default SearchResults;
