const initialState = {
  isLoading: false,
  isMobileView: false,
  emailSyncState: 'incomplete',
  settings: {
    privacy: 'public',
  },
  isDailyModalVisible: false,
  items: [],
  journals: [],
  measurements: {
    chest: '',
    waist: '',
    hips: '',
    shoulderWidth: '',
    sleeveLength: '',
    inseam: '',
    outseam: '',
    fullBodyLength: ''
  },
  subscription: {
    userType: 'free', // Default user type
    trialStartDate: null,
  },
  shelves: [{ id: 'fits' }],
  isFirstLogin: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.payload,
      };
    case 'SET_USER':
      return action.payload;
    case 'SET_EMAIL_SYNC_STATE':
      return {
        ...state,
        emailSyncState: action.payload
      }
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.payload
      };
    case 'UPDATE_USER_ITEMS':
      return {
        ...state,
        items: action.payload
      };
    case 'UPDATE_USER_SHELVES':
      return {
        ...state,
        shelves: action.payload
      };
    case 'USER_LOADING': {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case 'USER_VIBE': {
      return {
        ...state,
        vibe: action.payload
      };
    }
    case 'SHOW_DAILY_MODAL': {
      return {
        ...state,
        isDailyModalVisible: action.payload
      };
    }
    case 'USER_IS_MOBILE': {
      return {
        ...state,
        isMobileView: action.payload
      }
    }
    case 'UPDATE_MEASUREMENTS': {
      return {
        ...state,
        measurements: {
          ...state.measurements,
          ...action.payload
        }
      }
    }
    case 'SET_FIRST_LOGIN': {
      return {
        ...state,
        isFirstLogin: action.payload
      }
    }
    case 'UPDATE_JOURNALS': {
      return {
        ...state,
        journals: [...state.journals, action.payload],
      };
    }
    default:
      return state;
  }
};

export default userReducer;
