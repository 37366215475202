import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../helpers/styleUtil';
import monadImg from '../../assets/img/monad.png';

const StepContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepButton = styled.button`
  font-family: "Outfit", sans-serif;
  margin-top: 30px;
  padding: 8px 20px;
  font-size: 18px;
  cursor: pointer;
`;

const LekondoImage = styled.img`
  width: 25%; // Adjust the width as necessary
  max-width: 220px;
  margin-top: 5%; // Spacing between the image and the text
`;

const StepOne = ({ nextStep }) => {
  return (
    <StepContainer>
      <LekondoImage src={monadImg} alt="Monad Image" />
      <h2>Welcome to Lekondo</h2>
      <p>Build and organize your collection with ease.</p>
      <StepButton onClick={nextStep}>Next</StepButton>
    </StepContainer>
  );
};

export default StepOne;
