import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../helpers/styleUtil';
import { formatLocation } from '../../../helpers/util';
import { Brand, Category } from '../itemForm/ItemView'; // Adjust the import path as necessary

const ViewContainer = styled.div`
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
  height: 90vh;
  animation: ${fadeIn} 0.6s ease-out forwards;
  font-family: "Outfit", sans-serif;
`;

const Key = styled.p`
  color: black;
  margin: 0;
  padding-bottom: 5px;
`;

const Value = styled.span`
  color: #306844;
`;

const AttributeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  gap: 15px;
  width: 80%;
  font-size: 12px;
`;

const Attribute = styled.div`
  padding: 8px 16px;
  background-color: #f0ece2;
  border-radius: 15px;
  font-size: 13px;
`;

const LinkedItemsContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
`;

const LinkedItem = styled.div`
  cursor: pointer;
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: #000;

  &:hover {
    text-decoration: underline;
  }

  img {
    width: 120px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const ItemDescription = styled.div`
  border: 1px solid #e6e6e6;
  padding: 3%;
  margin-top: 20px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  overflow: auto;
  text-align: left;
  max-height: 260px;
  width: 90%;
  white-space: pre-wrap; /* Ensures that line breaks are preserved */
`;

const FitPicView = ({ itemDetails, aggregatedAttributes, items, handleLinkedItemClick }) => {
  return (<ViewContainer>
    <h1>{itemDetails.name}</h1>
    {itemDetails.isPrivate && <Key><span>&#40;</span>Private<span>&#41;</span></Key>}
    {itemDetails.listingDate && <Key>Date: <Value>{new Date(itemDetails.listingDate).toLocaleDateString()}</Value></Key>}
    {itemDetails.locationDetails && (
      <div>
        <Key>Location: <Value>{formatLocation(itemDetails.locationDetails)}</Value></Key>
      </div>
    )}
    <AttributeList>
      {aggregatedAttributes.brands.map(brand => (
        <Brand key={brand}>{brand}</Brand>
      ))}
      {aggregatedAttributes.categories.map(category => (
        <Category key={category}>{category}</Category>
      ))}
      {aggregatedAttributes.tags.map(tag => (
        <Attribute key={tag}>{tag}</Attribute>
      ))}
    </AttributeList>    
    {itemDetails.description && <ItemDescription>
      <Value className="description-value">{itemDetails.description}</Value>
    </ItemDescription>}
    <br />
    {itemDetails.linkedItems && itemDetails.linkedItems.length > 0 && (
      <LinkedItemsContainer>
        {itemDetails.linkedItems.map(linkedItem => {
          const item = items.find(i => i.id === linkedItem.itemId);
          return item ? (
            <LinkedItem key={linkedItem.itemId} onClick={() => handleLinkedItemClick(linkedItem.itemId)}>
              <img src={item.images[0]} alt={item.name} />
              {item.name}
            </LinkedItem>
          ) : null;
        })}
      </LinkedItemsContainer>
    )}
  </ViewContainer>
  );
};

export default FitPicView;
