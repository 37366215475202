import React, { useEffect, useState } from 'react';
import { AiTwotoneDelete } from "react-icons/ai";
import { GoArrowSwitch } from "react-icons/go";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import BrandBar from './BrandBar';
import TagBar from './TagBar';
import CategoryBar from './CategoryBar';
import ShelvesInput from './ShelvesInput';

import { conditionOptions } from '../../../constants/inventory';
import SelectInput from './SelectInput';
import { fadeIn } from '../../../helpers/styleUtil';
import { setViewEditMode } from '../../../redux/actions';

const ItemEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-out forwards;
  background-color: white;
  padding: 40px;
  color: #000;
  font-family: "Outfit", sans-serif;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

// Top button section with Save, Reverse, and Delete buttons
const TopButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

// Generic IconButton styling for reverse and delete icons
const IconButton = styled.div`
  cursor: pointer;
  font-size: 25px;
  color: #000;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

// Save button styling
const SaveButton = styled.button`
  font-family: "Outfit", sans-serif;
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

// Two-column layout for form fields on larger screens, switches to single-column on mobile
const FormColumns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

// Styling for individual form columns
const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Label styling
const Label = styled.label`
  font-size: 12px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5px;
`;

// Input field styling
const TextInput = styled.input`
  background: #f9f9f9;
  color: #000;
  padding: 12px;
  border: 1px solid #ddd;
  width: 85%;
  margin-bottom: 10px;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

// Description textarea styling
const DescriptionBox = styled.textarea`
  background: #f9f9f9;
  color: #000;
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  margin-top: 20px;
  height: 150px;
  border-radius: 4px;
  resize: none;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

// Collapsible section for advanced inputs
const CollapsibleSection = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
`;

// Collapsible header for advanced options
const CollapsibleHeader = styled.div`
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;
  
  &:hover {
    color: #333;
  }
`;

// Custom styling for the date picker input field
const StyledDatePicker = styled(DatePicker)`
  background: #f9f9f9;
  color: #000;
  padding: 10px;
  border: 1px solid #ddd;
  width: 85%;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

// Styling for the status select dropdown
const StatusSelect = styled.select`
  background: #f9f9f9;
  color: #000;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const PrivateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

// Label for private checkbox
const PrivateLabel = styled.label`
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &:checked {
    background-color: #000;
  }

  &:checked::after {
    content: '✔';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border-color: #666;
  }

  &:focus {
    outline: none;
    border-color: #333;
  }
`;
const PriceCollapsibleSection = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
`;

// Collapsible header for prices
const PriceCollapsibleHeader = styled.div`
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: #333;
  }
`;

const StatusOptions = [
  { value: 'Kondo', label: 'Sell' },
  { value: 'isOwned', label: 'Owned' },
  { value: 'Wishlist', label: 'Wishlist' },
  { value: 'Archive', label: 'Archive' },
];

const ItemEdit = ({ itemDetails, setItemDetails, onSave, handleDelete }) => {
  const dispatch = useDispatch();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [isPriceSectionOpen, setIsPriceSectionOpen] = useState(false); // State to control the collapse of prices

  const handleModalClick = (event) => {
    event.stopPropagation(); // Stop clicks from propagating to the document level
  };

  const onView = () => {
    dispatch(setViewEditMode(false)); // Start in view mode when editing an existing item
  }

  const handleStatusChange = (e) => {
    setItemDetails({ ...itemDetails, status: e.target.value });
  };


  useEffect(() => {
    const { status, tags = [] } = itemDetails;
    const statusTags = ['Wishlist', 'Kondo', 'Archive'];

    let updatedTags = tags.filter(tag => !statusTags.includes(tag));

    if (statusTags.includes(status) && !updatedTags.includes(status)) {
      updatedTags.push(status);
    }

    setItemDetails({ ...itemDetails, tags: updatedTags });
  }, [itemDetails.status]);

  return (
    <ItemEditContainer onClick={handleModalClick}>
      <TopButtonSection>
        <IconButton>
          <GoArrowSwitch onClick={onView} />
        </IconButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
        <IconButton>
          <AiTwotoneDelete onClick={handleDelete} />
        </IconButton>
      </TopButtonSection>

      <FormColumns>
        <FormColumn>
          <Label>Name</Label>
          <TextInput
            type="text"
            value={itemDetails.name}
            onChange={e => setItemDetails({ ...itemDetails, name: e.target.value })}
          />

          <Label>Color</Label>
          <TextInput
            type="text"
            value={itemDetails.color}
            onChange={e => setItemDetails({ ...itemDetails, color: e.target.value })}
          />

          <Label>Listing Date</Label>
          <StyledDatePicker
            selected={itemDetails.listingDate ? new Date(itemDetails.listingDate) : null}
            onChange={date => setItemDetails({ ...itemDetails, listingDate: date.toISOString() })}
          />

          <Label>Status</Label>
          <StatusSelect value={itemDetails.status} onChange={handleStatusChange}>
            {StatusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </StatusSelect>
        </FormColumn>

        <FormColumn>
          <ShelvesInput itemDetails={itemDetails} setItemDetails={setItemDetails} />

          {/* Collapsible section for prices */}
          <PriceCollapsibleSection>
            <PriceCollapsibleHeader onClick={() => setIsPriceSectionOpen(!isPriceSectionOpen)}>
              Prices
            </PriceCollapsibleHeader>

            {isPriceSectionOpen && (
              <>
                <Label>Retail Price</Label>
                <TextInput
                  type="text"
                  value={itemDetails.retailPrice}
                  onChange={e => setItemDetails({ ...itemDetails, retailPrice: Number(e.target.value) })}
                />

                <Label>Purchased Price</Label>
                <TextInput
                  type="text"
                  value={itemDetails.price}
                  onChange={e => setItemDetails({ ...itemDetails, price: Number(e.target.value) })}
                />

                <Label>Sale Price</Label>
                <TextInput
                  type="text"
                  value={itemDetails.salePrice}
                  onChange={e => setItemDetails({ ...itemDetails, salePrice: Number(e.target.value) })}
                />
              </>
            )}
          </PriceCollapsibleSection>

          <CollapsibleSection>
            <CollapsibleHeader onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}>
              Options
            </CollapsibleHeader>
            {isAdvancedOpen && (
              <>
                <BrandBar itemDetails={itemDetails} setItemDetails={setItemDetails} />
                <TagBar itemDetails={itemDetails} setItemDetails={setItemDetails} />
                <CategoryBar itemDetails={itemDetails} setItemDetails={setItemDetails} />
                <Label>Condition</Label>
                <SelectInput
                  itemDetails={itemDetails}
                  setItemDetails={setItemDetails}
                  options={conditionOptions}
                  placeholder="Condition"
                />
                <PrivateContainer>
                  <PrivateLabel>Private</PrivateLabel>
                  <CustomCheckbox
                    checked={itemDetails.isPrivate || false}
                    onChange={e => setItemDetails({ ...itemDetails, isPrivate: e.target.checked })}
                  />
                </PrivateContainer>
              </>
            )}
          </CollapsibleSection>

          <Label>Quantity</Label>
          <TextInput
            type="text"
            value={itemDetails.quantity}
            onChange={e => setItemDetails({ ...itemDetails, quantity: e.target.value })}
          />
        </FormColumn>
      </FormColumns>

      <DescriptionBox
        value={itemDetails.description}
        onChange={e => setItemDetails({ ...itemDetails, description: e.target.value })}
      />
    </ItemEditContainer>
  );
};

export default ItemEdit;