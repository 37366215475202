import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { fadeIn } from '../../../helpers/styleUtil';
import { setCurrentItem, setViewEditMode } from '../../../redux/actions';
import FitPicScreen from '../FitPic/FitPicScreen';
import { serverUrl } from '../../../constants/urls';

const ItemViewContainer = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  background: white;
  width: 100%;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
  max-height: ${({ isMobileView }) => (isMobileView ? 'auto' : '100%')};
  font-family: "Montserrat", sans-serif;
`;

const ItemDetailView = styled.div`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  justify-content: space-between;
  align-items: flex-start;
`;

const ItemInfo = styled.div`
  flex: 1;
  padding-top: 20px;
  padding-right: 10px;
  min-height: 50%;
  overflow: auto;
`;

const AttributesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 15px;
  font-size: 13px;
  overflow-y: auto;
  max-width: ${({ isMobileView }) => (isMobileView ? '100%' : '35%')};
  max-height: ${({ isMobileView }) => (isMobileView ? 'auto' : '250px')};
`;

const Tag = styled.span`
  padding: 8px 16px;
  background-color: #f0ece2;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &.selected {
    border: 2px dotted black;
    background-color: #E2ECE6;
    opacity: 0.9;
    color: black;
    font-size: 15px;
  }
`;

export const Brand = styled(Tag)`
  background-color: #5F5449;
  color: white;
`;

export const Category = styled(Tag)`
  background-color: #518384;
  color: white;
`;

const ItemDescription = styled.div`
  border: 1px solid #e6e6e6;
  padding: 3%;
  margin-top: 20px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  overflow: auto;
  text-align: left;
  min-height: 150px;
  max-height: 260px;
  width: 90%;
      white-space: pre-wrap; /* Ensures that line breaks are preserved */
`;

const Key = styled.p`
  font-size: 14px;
  color: #ccc;
`;

const Value = styled.span`
  font-size: 16px;
  color: black;
`;

const EditButton = styled.button`
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  max-width: 300px;
  width: ${({ isMobileView }) => (isMobileView ? '90%' : '70%')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 10px 20px;
  background-color: black;
  color: white;
    border: 1px solid #000;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const LinkedItemsContainer = styled.div`
  display: grid;
    flex-direction: row;
    padding-top: 30px;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  overflow-y: auto;
`;


const LinkedItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;

  &:hover {
    text-decoration: underline;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
  }
`;

const ShareButton = styled.button`
  width: auto;
  padding: 10px 30px;
  background-color: #fff;
  margin-right: 20px;
  color: #000;
  border: 1px solid #000;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

const ItemView = ({ itemDetails }) => {
  const dispatch = useDispatch();
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const { email, isMobileView } = useSelector(state => state.user);
  const items = useSelector(state => state.inventory.items);

  const combinedAttributes = [
    ...(itemDetails.tags?.length > 0 ? itemDetails.tags.map(tag => ({ type: 'tag', value: tag })) : []),
    ...(itemDetails.brands?.length > 0 ? itemDetails.brands.map(brand => ({ type: 'brand', value: brand })) : []),
    ...(itemDetails.categories?.length > 0 ? itemDetails.categories.map(category => ({ type: 'category', value: category })) : [])
  ];

  const onEdit = () => {
    dispatch(setViewEditMode(true)); // Start in view mode when editing an existing item
  }

  const renderAttribute = (attr) => {
    const { type, value } = attr;
    const Component = type === 'brand' ? Brand : type === 'category' ? Category : Tag;
    return <Component key={`${type}-${value}`}>{value}</Component>;
  };

  const handleLinkedItemClick = (itemId) => {
    const linkedItem = items.find(item => item.id === itemId);
    if (linkedItem) {
      dispatch(setCurrentItem(linkedItem));
    }
  };

  const handleShare = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const emailForSharing = visitingUserDetails?.isVisiting ?
      visitingUserDetails?.email :
      email;

    try {
      const response = await fetch(`${serverUrl}/api/share`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: emailForSharing, itemId: itemDetails.id })
      });
      const data = await response.json();
      navigator.clipboard.writeText(data.shareableLink);
      toast.success('Link copied to clipboard!');
    } catch (error) {
      console.error('Error sharing item:', error);
      toast.error('Failed to generate share link for item');
    }
  };


  return (
    <ItemViewContainer isMobileView={isMobileView}>
      {itemDetails.itemType === 'fits' ? (
        <FitPicScreen item={itemDetails} />
      ) : (
        <>
          <ItemDetailView isMobileView={isMobileView}>
            <ItemInfo>
              <ShareButton onClick={handleShare}>Share</ShareButton>
              <h3>{itemDetails.name}</h3>
              {itemDetails.color && <Key>Color: <Value>{itemDetails.color}</Value></Key>}
              {itemDetails.size && itemDetails.size.length > 0 && <Key>Size: <Value>{itemDetails.size}</Value></Key>}
              {itemDetails.quantity && <Key>Quantity: <Value>{itemDetails.quantity}</Value></Key>}
              {itemDetails?.retailPrice != null && itemDetails.retailPrice !== 0 && (
                <Key>
                  Retail Price: <Value>${itemDetails.retailPrice}</Value>
                </Key>
              )}
              {itemDetails?.price != null && itemDetails.price !== 0 && (
                <Key>
                  Price: <Value>${itemDetails.price}</Value>
                </Key>
              )}
              {itemDetails.source && (
                <div>
                  {itemDetails.source?.fromWebsiteUrl && (
                    <Key>Source: <a href={itemDetails.source.fromWebsiteUrl} target="_blank" rel="noopener noreferrer"><Value>{itemDetails.source.fromWebsiteUrl.substring(0, 25) + '...'}</Value></a></Key>
                  )}
                </div>
              )}
              {itemDetails.isPrivate && <Key><span>&#40;</span>Private<span>&#41;</span></Key>}
              {itemDetails.listingDate && <Key>Date: <Value>{new Date(itemDetails.listingDate).toLocaleDateString()}</Value></Key>}
              {itemDetails.lastUpdated && <Key>Last Updated: <Value>{new Date(itemDetails.lastUpdated).toLocaleDateString()}</Value></Key>}
            </ItemInfo>
            <AttributesList isMobileView={isMobileView}>
              {combinedAttributes.map(attr => renderAttribute(attr))}
            </AttributesList>
          </ItemDetailView>
          {(!visitingUserDetails?.isVisiting || email === 'lekondotest@gmail.com') && <EditButton isMobileView={isMobileView} onClick={onEdit}>Edit</EditButton>}
          {itemDetails.linkedItems && itemDetails.linkedItems.length > 0 && (
            <LinkedItemsContainer>
              {itemDetails.linkedItems.map(linkedItem => {
                const item = items.find(i => i.id === linkedItem.itemId);
                return item ? (
                  <LinkedItem key={linkedItem.itemId} onClick={() => handleLinkedItemClick(linkedItem.itemId)}>
                    <img src={item.images[0]} alt={item.name} />
                  </LinkedItem>
                ) : null;
              })}
            </LinkedItemsContainer>
          )}
          {itemDetails.description && <ItemDescription>
            <Value className="description-value">{itemDetails.description}</Value>
          </ItemDescription>}
        </>
      )}
    </ItemViewContainer>
  );
};

export default ItemView;
