import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';

import Mixpanel from './helpers/mixpanel';
import initializeSocket from './redux/middleware/socket';
import { initializeGA, logPageView } from './analytics';
import { setCompleteItems, userIsMobile, setVisitingUserDetails } from './redux/actions';
import FooterBar from './components/Footer/FooterBar';
import Loading from './components/Loading/Loading';
import MobileView from './components/Mobile/MobileView';
import AppRoutes from './AppRoutes';

import store from './redux/store';
import { serverUrl } from './constants/urls';
import { fadeIn } from './helpers/styleUtil';

const AppContainer = styled.div`
  height: 98vh;
  display: flex;
  flex-direction: row;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  padding-top: 5px;
  @media (max-width: 800px) {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
  position: relative;
  z-index: 1;
`;

const MobileUploadContainer = styled.div`
animation: ${fadeIn} 0.6s ease-out forwards;
`;

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
};

const App = () => {
  usePageViews();

  const navigate = useNavigate();
  const sessionStartTime = useRef(new Date());
  const currentDay = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
  const dispatch = useDispatch();
  const { isLoading, user, isMobileView, visitingUserDetails } = useSelector(state => ({
    isLoading: state.user.isLoading,
    user: state.user,
    isMobileView: state.user.isMobileView,
    visitingUserDetails: state.visitingUserDetails
  }));

  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    initializeSocket(store);
  }, []);


  useEffect(() => {
    if (user?.email) {
      Mixpanel.track('Session Start', {
        userEmail: user?.email
      });

      const handleBeforeUnload = () => {
        const sessionEndTime = new Date();
        const sessionDuration = (sessionEndTime - sessionStartTime.current) / 1000; // in seconds

        Mixpanel.track('Session End', {
          userEmail: user?.email,
          sessionStartTime: sessionStartTime.current.toISOString(),
          sessionEndTime: sessionEndTime.toISOString(),
          sessionDuration: sessionDuration
        });
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        // Track session end if the component unmounts
        handleBeforeUnload();
      };
    }
  }, [user?.email]);



  useEffect(() => {
    const handleResize = () => {
      dispatch(userIsMobile(window.innerWidth <= 800));
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [user?.email, dispatch]);

  useEffect(() => {
    const uuid = new URLSearchParams(window.location.search).get('uuid');
    if (uuid) {
      fetch(`${serverUrl}/api/shared-wardrobe?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('UUID validation failed');
          }
          return response.json();
        })
        .then(data => {
          if (data.visitingUserDetails) {
            dispatch(setVisitingUserDetails(data.visitingUserDetails));
            dispatch(setCompleteItems(data.visitingUserDetails.items));
          } else {
            toast.error('Link Failed: No items found');
            throw new Error('No items found');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Error loading shared items');
          navigate('/'); // Redirect to root if the token is invalid or fetching fails
        });
    }
  }, [dispatch]);

  return (
    <AppContainer>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading && <Loading />}
      {isMobileView && user?.email && !visitingUserDetails?.isVisiting && (
        <MobileUploadContainer>
          <MobileView />
        </MobileUploadContainer>
      )}
      <AppRoutes />
      {(!isMobileView) && <FooterBar />}
    </AppContainer>
  );
};

export default App;
