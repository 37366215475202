// src/redux/visitingUserItemsReducer.js
import { SET_VISITING_USER_DETAILS, CLEAR_VISITING_USER_DETAILS, UPDATE_VISITING_USER_VIBE } from '../actions';

const initialState = {
  items: [],
  name: null,
  isVisiting: false,
  email: null,
  vibe: null
};

const visitingUserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISITING_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
        isVisiting: true
      };
    case CLEAR_VISITING_USER_DETAILS:
      return initialState;
    case UPDATE_VISITING_USER_VIBE:
      return {
        ...state,
        vibe: action.payload
      };
    default:
      return state;
  }
};

export default visitingUserDetailsReducer;
