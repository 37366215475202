export const setUser = (user) => ({
  type: 'SET_USER',
  payload: user,
});

export const updateUserItems = (items) => ({
  type: 'UPDATE_USER_ITEMS',
  payload: items,
});

export const updateUserShelves = (shelves) => ({
  type: 'UPDATE_USER_SHELVES',
  payload: shelves,
});

export const updateUserSubscription = (subscription) => ({
  type: 'UPDATE_USER_SUBSCRIPTION',
  payload: subscription,
});

export const setSettings = (settings) => ({
  type: 'SET_SETTINGS',
  payload: settings,
});

export const clearUser = () => ({
  type: 'CLEAR_USER'
});

export const updateJournals = (journals) => ({
  type: 'UPDATE_JOURNALS',
  payload: journals
});

export const userLoading = (loading) => ({
  type: 'USER_LOADING',
  payload: loading
});

export const userVibe = (data) => ({
  type: 'USER_VIBE',
  payload: data
});

export const userIsMobile = (isMobile) => ({
  type: 'USER_IS_MOBILE',
  payload: isMobile
});

export const updateMeasurements = (measurements) => ({
  type: 'UPDATE_MEASUREMENTS',
  payload: measurements
});

export const setFirstLogin = (isFirstLogin) => ({
  type: "SET_FIRST_LOGIN",
  payload: isFirstLogin
})