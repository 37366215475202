import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userLoading } from '../../redux/actions';
import styled from 'styled-components';
import monadImg from '../../assets/img/monad.png';

const ONE_SECOND = 1000;
const FIVE_MINUTE = 1000 * 60 * 5;
const THIRTY_SECONDS = 10000 * 3;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); // Light white background
  backdrop-filter: blur(10px); // Blur effect
  display: flex;
  flex-direction: column; // Stack children vertically
  justify-content: center;
  align-items: center;
  z-index: 2000; // Ensure it's on top of everything else
`;

const LoadingSpinner = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

const LekondoImage = styled.img`
  width: 15%; // Adjust the width as necessary
  margin-bottom: 5%; // Spacing between the image and the text
`;

const CancelButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const Loading = () => {
  const [loadingText, setLoadingText] = useState('Loading....');
  const [showCancelButton, setShowCancelButton] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const textInterval = setInterval(() => {
      setLoadingText(prev => prev === 'Loading....' ? 'Loading...' : 'Loading....');
    }, ONE_SECOND);

    const timeout = setTimeout(() => {
      dispatch(userLoading(false));
    }, FIVE_MINUTE); // Stop loading after one minute

    const cancelTimeout = setTimeout(() => {
      setShowCancelButton(true);
    }, THIRTY_SECONDS); // Show cancel button after 10 seconds

    return () => {
      clearInterval(textInterval);
      clearTimeout(timeout);
      clearTimeout(cancelTimeout);
    };
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(userLoading(false));
  };

  return (
    <LoadingOverlay>
      <LekondoImage src={monadImg} alt="Loading Image" />
      <LoadingSpinner>
        {loadingText}
      </LoadingSpinner>
      {showCancelButton && (
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
      )}
    </LoadingOverlay>
  );
};

export default Loading;
