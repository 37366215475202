import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, IconButton } from '@mui/material';

import lekondoImg from '../assets/img/lekondo.png';
import { formatDate } from '../helpers/util';

const WardrobeSummaryWrapper = styled.div`
  font-size: 18px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #555;
`;

const LekondoTitle = styled.img`
display:block;
  cursor: pointer;
opacity: 0.9;
max-width: 120px;
width: 100%;
margin: 0 auto;
margin-top: 10px;

&: hover {
opacity: 0.5;
}

  `
const WardrobeSummaryText = styled.div`
  height: 100%;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  font-size: 17px;
   max-height: 13.5vh;
  overflow-y: auto;
`;

const PaintingImage = styled.img`
  width: 130px;
  height: 130px;
  display: block;
  margin: 0 auto 10px auto;
  margin-right: 30px;
  border-radius: 16px;
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const WardrobeSummary = () => {
  const navigate = useNavigate();
  const todayDate = formatDate(new Date());
  const userVibe = useSelector(state => state.user.vibe); // Select the entire vibe data
  const visitingUserDetails = useSelector(state => state.visitingUserDetails); // Select the entire vibe data
  // Use the visiting user's vibe if applicable
  const vibeData = visitingUserDetails.isVisiting ? visitingUserDetails.vibe : userVibe;
  const items = useSelector(state => state.inventory.items);
  const defaultQuote = '"The question of what you want to own, is actually the question of how you want to live your life" - Marie Kondo';

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getSummaryText = () => {
    if (items.length < 5 || !vibeData?.summary) {
      return defaultQuote;
    }
    return vibeData.summary;
  };

  return (
    <WardrobeSummaryWrapper>
      <LekondoTitle src={lekondoImg} onClick={() => navigate('/')} />
      <div className="view-switcher">
        <h2 style={{ textAlign: 'center', fontSize: '13px' }}>{todayDate}</h2>
        <br />
        <SummaryContainer>
          {vibeData?.paintingUrl && (
            <PaintingImage src={vibeData.paintingUrl} alt="Abstract Painting" onClick={handleOpen} />
          )}
          <WardrobeSummaryText>
            {getSummaryText()}
          </WardrobeSummaryText>

        </SummaryContainer>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '65%', height: '85%', bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: 24 }}>
          <IconButton className="slider-arrow" onClick={handleClose} sx={{ position: 'absolute', right: 15, top: 15 }}>
            ✕
          </IconButton>
          <img
            src={vibeData?.paintingUrl}
            alt="Abstract Painting"
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </Box>
      </Modal>
    </WardrobeSummaryWrapper>
  );
};

export default WardrobeSummary;
