import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled as muiStyled } from '@mui/material/styles';

import { updateLinkedItems } from '../../../redux/actions';

// Styled component using Material-UI's styled utility
const LinkedItemsInputContainer = muiStyled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiTextField-root': {
        marginBottom: theme.spacing(1)
    }
}));

const LinkedItemsInput = ({ itemDetails, setItemDetails }) => {
    const dispatch = useDispatch();
    const items = useSelector((state) => state.inventory.items);
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (itemDetails.linkedItems && Array.isArray(itemDetails.linkedItems)) {
            setSelectedItems(itemDetails.linkedItems.map((linkedItem) => {
                const item = items.find(i => i.id === linkedItem.itemId);
                return item ? item.name : '';
            }).filter(name => name)); // Filter out any undefined or null names
        }
    }, [itemDetails.linkedItems, items]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
        const linkedItemIds = newValue.map((itemName) => {
            const item = items.find(i => i.name === itemName);
            return item ? item.id : null;
        }).filter(itemId => itemId);

        const newLinkedItems = linkedItemIds.map(id => ({ itemId: id }));
        setItemDetails({ ...itemDetails, linkedItems: newLinkedItems });
        setSelectedItems(newValue); // Update selectedItems with the new names
        dispatch(updateLinkedItems(itemDetails.id, linkedItemIds));
    };

    const itemType = itemDetails.itemType || 'item';

    // Filter items based on itemType, handling legacy if itemType isn't available to the item
    const filteredItems = items.filter(item => {
        if (itemType === 'item') {
            return item.itemType === 'fits' || !item.itemType;
        } else if (itemType === 'fits') {
            return item.itemType === 'item';
        }
        return false;
    });

    return (
        <LinkedItemsInputContainer>
            <Autocomplete
                multiple
                freeSolo
                disablePortal
                placement="bottom-start"
                id="linked-items-input"
                value={selectedItems}
                options={filteredItems.map(item => item.name)}
                getOptionLabel={(option) => option}
                onInputChange={handleInputChange}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label="Linked" variant="outlined" fullWidth />}
            />
        </LinkedItemsInputContainer>
    );
};

export default LinkedItemsInput;
