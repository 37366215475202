import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { addItem } from '../../redux/actions';

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e6e6e6;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  position: relative;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: auto;
  border-radius: 4px;
  margin-right: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    width: 35%;
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 1em;
  margin: 0;
  color: black;
  text-align: left;
  word-wrap: break-word;
  max-width: 100%;
`;

const Price = styled.p`
  font-size: 0.9em;
  color: #333;
  margin: 5px 0;
`;

const ProductDetails = styled.div`
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SourceLink = styled.a`
  font-size: 0.8em;
  color: #71d0ef;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const AddButton = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const SearchResultCard = ({ result, onItemAdded }) => {
  const dispatch = useDispatch();
  const { name, images, source, description } = result;

  const handleAddClick = () => {
    dispatch(addItem(result));
    console.log(`Added ${name} to the wardrobe.`);
    if (onItemAdded) {
      onItemAdded(result);
    }
  };

  const trimmedDescription = description.length > 100 ? description.substring(0, 100) + '...' : description;


  return (
    <Card>
      <Thumbnail src={images[0]} alt={name} />
      <InfoContainer>
        <Title>{name}</Title>
        <ProductDetails>{trimmedDescription}</ProductDetails>
        <SourceLink href={source.fromWebsiteUrl} target="_blank" rel="noopener noreferrer">
          {source.fromWebsiteUrl ? 'View Product' : 'Unknown Source'}
        </SourceLink>
      </InfoContainer>
      <AddButton onClick={handleAddClick}>Add</AddButton>
    </Card>
  );
};

export default SearchResultCard;
