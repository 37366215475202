import io from 'socket.io-client';
import { serverUrl } from '../../constants/urls';
import { setCompleteItems, setItemsFromServer, setFirstLogin, userLoading, updateUserSubscription } from '../actions';

let socket;

const initializeSocket = (store) => {
  if (socket) {
    socket.disconnect(); // Disconnect existing socket to avoid multiple connections
  }

  socket = io(serverUrl); // Initialize socket connection with the back-end server

  socket.on('connect', () => {
    console.log('Connected to WebSocket server');
  });

  socket.on('processingComplete', (data) => {
    console.log('Processing complete from server:', data);
    const state = store.getState();
    const loggedInUserEmail = state.user.email;
    if (data.email === loggedInUserEmail) {
      // Dispatch an action to update the state in the store and redirect the user
      store.dispatch(setCompleteItems(data.items));
      store.dispatch(setFirstLogin(false));
    }
  });

  socket.on('processingFailed', (data) => {
    console.log('Processing failed from server:', data);
    const state = store.getState();
    const loggedInUserEmail = state.user.email;
    if (data.email === loggedInUserEmail) {
      // Dispatch an action to update the state in the store and redirect the user
      store.dispatch(setFirstLogin(false));
    }
  });

  socket.on('stateUpdated', (data) => {
    console.log('State updated from server:', data);
    const state = store.getState();
    const loggedInUserEmail = state.user.email;
    if (data.user.email === loggedInUserEmail) {
      // Dispatch an action to update the state in the store
      store.dispatch(setItemsFromServer(data.user.items));
      store.dispatch(userLoading(false));
    }
  });

  socket.on('userUpdated', (data) => {
    console.log('User updated from server:', data);
    const state = store.getState();
    const loggedInUserEmail = state.user.email;
    if (data.email === loggedInUserEmail) {
      // Dispatch an action to update the user subscription in the store
      store.dispatch(updateUserSubscription(data.subscription));
      store.dispatch(userLoading(false));
    }
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from WebSocket server');
  });
};

export default initializeSocket;
