import ReactGA from 'react-ga4';

// eslint-disable-next-line no-undef
const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS;

const initializeGA = () => {
  ReactGA.initialize(googleAnalyticsKey);
};

const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

const useAnalyticsEventTracker = (category = 'Event Category') => {
  const eventTracker = (action = 'action', label = 'label') => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };
  return eventTracker;
};

export { initializeGA, logPageView, useAnalyticsEventTracker };