// src/redux/rootReducer.js
import { combineReducers } from 'redux';
import inventoryReducer from './inventoryReducer';
import userReducer from './userReducer';
import discoveryReducer from './discoveryReducer';
import visitingUserDetailsReducer from './visitingUserDetailsReducer';

const appReducer = combineReducers({
    inventory: inventoryReducer,
    user: userReducer,
    discovery: discoveryReducer,
    visitingUserDetails: visitingUserDetailsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_USER') {
        state = undefined;  // This will reset the entire state, forcing all reducers to use their initial state
    }

    return appReducer(state, action);
};

export default rootReducer;