import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import WaitingScreen from './WaitingScreen';
import { fadeIn } from '../../helpers/styleUtil';

const Overlay = styled.div`
animation: ${fadeIn} 0.5s ease-out forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const OnboardingContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1000px;
  text-align: center;
`;

const OnboardingScreen = ({ onFinish }) => {
    const user = useSelector(state => state.user);
    const [step, setStep] = useState(user?.emailSyncState === 'pending' ? 4 : 1);

    const nextStep = () => {
        setStep(prev => prev + 1);
    };

    return (
        <Overlay>
            <OnboardingContainer>
                {step === 1 && <StepOne nextStep={nextStep} />}
                {step === 2 && <StepTwo nextStep={onFinish} />}
            </OnboardingContainer>
        </Overlay>
    );
};
export default OnboardingScreen;