// src/redux/reducers/discoveryReducer.js
import {
  FETCH_DISCOVERY_USERS_REQUEST,
  FETCH_DISCOVERY_USERS_SUCCESS,
  FETCH_DISCOVERY_USERS_FAILURE,
  ADD_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  ADD_LIKE,
  REMOVE_LIKE
} from '../actions/discoveryActions';

const initialState = {
  users: [],
  status: 'idle',
  error: null
};

const discoveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISCOVERY_USERS_REQUEST:
      return {
        ...state,
        status: 'loading'
      };
    case FETCH_DISCOVERY_USERS_SUCCESS:
      return {
        ...state,
        status: 'succeeded',
        users: action.page === 1 ? action.payload : [...state.users, ...action.payload]
      };
    case FETCH_DISCOVERY_USERS_FAILURE:
      return {
        ...state,
        status: 'failed',
        error: action.payload
      };
    case ADD_COMMENT:
      return {
        ...state,
        users: state.users.map(user =>
          user.email === action.payload.fitOwnerEmail ? {
            ...user,
            fits: user.fits.map(fit =>
              fit.id === action.payload.fitId ? {
                ...fit,
                comments: [...(fit.comments || []), action.payload.comment]
              } : fit
            )
          } : user
        )
      };
    case EDIT_COMMENT:
      return {
        ...state,
        users: state.users.map(user =>
          user.email === action.payload.fitOwnerEmail ? {
            ...user,
            fits: user.fits.map(fit =>
              fit.id === action.payload.fitId ? {
                ...fit,
                comments: fit.comments.map((comment, index) =>
                  index === action.payload.index ? { ...comment, text: action.payload.newText } : comment
                )
              } : fit
            )
          } : user
        )
      };
    case DELETE_COMMENT:
      return {
        ...state,
        users: state.users.map(user =>
          user.email === action.payload.fitOwnerEmail ? {
            ...user,
            fits: user.fits.map(fit =>
              fit.id === action.payload.fitId ? {
                ...fit,
                comments: fit.comments.filter((_, index) => index !== action.payload.index)
              } : fit
            )
          } : user
        )
      };
    case ADD_LIKE:
      return {
        ...state,
        users: state.users.map(user =>
          user.email === action.payload.fitOwnerEmail ? {
            ...user,
            fits: user.fits.map(fit =>
              fit.id === action.payload.fitId ? {
                ...fit,
                likes: [...(fit.likes || []), { email: action.payload.userEmail, author: action.payload.author }]
              } : fit
            )
          } : user
        )
      };
    case REMOVE_LIKE:
      return {
        ...state,
        users: state.users.map(user =>
          user.email === action.payload.fitOwnerEmail ? {
            ...user,
            fits: user.fits.map(fit =>
              fit.id === action.payload.fitId ? {
                ...fit,
                likes: fit.likes.filter(like => like.email !== action.payload.userEmail)
              } : fit
            )
          } : user
        )
      };
    default:
      return state;
  }
};

export default discoveryReducer;
