// src/redux/actions.js
export const SET_VISITING_USER_DETAILS = 'SET_VISITING_USER_DETAILS';
export const CLEAR_VISITING_USER_DETAILS = 'CLEAR_VISITING_USER_DETAILS';
export const UPDATE_VISITING_USER_VIBE = 'UPDATE_VISITING_USER_VIBE';

export const setVisitingUserDetails = (details) => {
  const { items, email, name, vibe } = details;
  return {
    type: SET_VISITING_USER_DETAILS,
    payload: {
      name,
      items,
      email,
      vibe
    }
  };
};

export const clearVisitingUserDetails = () => ({
  type: CLEAR_VISITING_USER_DETAILS,
});

export const updateVisitingUserVibe = (vibeData) => ({
  type: UPDATE_VISITING_USER_VIBE,
  payload: vibeData
});