import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, Button } from '@mui/material';
import styled from 'styled-components';
import * as d3 from 'd3';
import DatePicker from 'react-datepicker';
import { fadeIn } from '../../../helpers/styleUtil';
import { updateMeasurements } from '../../../redux/actions';

import { createBoxPlot, createScatterPlot, createSavingsPlot } from './GraphPlots';

// Styled components for various parts of the UI
const Tooltip = styled.div`
  min-width: 100px;
  position: absolute;
  text-align: center;
  background: white;
  border: 1px solid #ccc;
  padding: 8px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DataBox = styled(Box)`
  animation: ${fadeIn} 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  font-family: "Outfit", sans-serif;
`;

const TabsContainer = styled(Tabs)`
  margin-top: 20px;
  margin-bottom: 50px;
  .MuiTabs-flexContainer {
    justify-content: center;
  }
`;

const ChartContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #eaeaea;
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: 16px;

  .react-datepicker-wrapper {
    width: auto;
  }

  input {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;

    &:focus {
      border-color: #333;
    }
  }
`;

const StyledTab = styled(Tab)`
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  padding: 20px;
  border: 1px solid #eaeaea;
  width: 80%;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const SummaryItem = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const MeasurementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  border: 1px solid #eaeaea;
  width: 50%;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Outfit", sans-serif;
`;

const MeasurementItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  label {
    flex-basis: 40%;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  input {
    flex-basis: 55%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
`;

const SaveButton = styled(Button)`
  align-self: center;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
`;

const DataView = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();

  const storedMeasurements = useSelector(state => state.user.measurements);

  const [measurements, setMeasurements] = useState(storedMeasurements || {
    chest: '',
    waist: '',
    hips: '',
    shoulderWidth: '',
    sleeveLength: '',
    inseam: '',
    outseam: '',
    fullBodyLength: ''
  });

  const measurementLabels = {
    chest: "Chest (cm)",
    waist: "Waist (cm)",
    hips: "Hips (cm)",
    shoulderWidth: "Shoulder Width (cm)",
    sleeveLength: "Sleeve Length (cm)",
    inseam: "Inseam (cm)",
    outseam: "Outseam (cm)",
    fullBodyLength: "Full Body Length (cm)"
  };

  const items = useSelector(state => state.inventory.items).filter(item => item.status !== 'Wishlist');

  const totalItems = items.reduce((sum, item) => sum + (item.quantity || 1), 0);
  const totalCost = items.reduce((sum, item) => sum + (parseFloat(item.price || 0) * (item.quantity || 1)), 0);
  const uniqueBrands = [...new Set(items.map(item => item.brands).flat())];

  useEffect(() => {
    if (items.length > 0) {
      const listingDates = items
        .map(item => (item.listingDate ? d3.isoParse(item.listingDate) : null))
        .filter(date => date);

      const minDate = d3.min(listingDates);
      const maxDate = d3.max(listingDates);
      // Add one month to the maxDate
      const maxDateWithMonthAdded = new Date(maxDate);
      maxDateWithMonthAdded.setMonth(maxDateWithMonthAdded.getMonth() + 1);

      // Update state only if the new values are different
      if (!startDate || startDate.getTime() !== minDate.getTime()) {
        setStartDate(minDate);
      }
      if (!endDate || endDate.getTime() !== maxDateWithMonthAdded.getTime()) {
        setEndDate(maxDateWithMonthAdded);
      }
    }
  }, [items]);

  useEffect(() => {
    const priceData = items.reduce((acc, item) => {
      const price = parseFloat(item.price) || 0;
      if (!acc[price]) {
        acc[price] = { price, names: [] };
      }
      acc[price].names.push(item.name);
      return acc;
    }, {});

    const groupedData = Object.values(priceData).sort((a, b) => a.price - b.price);

    let scatterData = items.flatMap(item => Array.from({ length: item.quantity || 1 }, () => ({
      date: item.listingDate ? d3.isoParse(item.listingDate) : null,
      price: parseFloat(item.price) || 0,
      name: item.name,
    }))).filter(d => d.date && !isNaN(d.price));

    // Filter data based on date range
    if (startDate && endDate) {
      scatterData = scatterData.filter(d => d.date >= startDate && d.date <= endDate);
    }

    const maxDate = d3.max(items.map(item => item.listingDate ? d3.isoParse(item.listingDate) : null).filter(date => date));
    const maxDateWithMonthAdded = new Date(maxDate);
    maxDateWithMonthAdded.setMonth(maxDateWithMonthAdded.getMonth() + 1);

    if (tabIndex === 0) {
      createBoxPlot(chartRef.current, groupedData, tooltipRef.current);
    } else if (tabIndex === 1) {
      createScatterPlot(chartRef.current, scatterData, tooltipRef.current, maxDateWithMonthAdded);
    } else if (tabIndex === 2) {
      const savingsData = items.map(item => ({
        name: item.name,
        price: parseFloat(item.price) || 0,
        retailPrice: parseFloat(item.retailPrice) || 0,
      }));
      createSavingsPlot(chartRef.current, savingsData, tooltipRef.current);
    }

    function handleResize() {
      if (tabIndex === 0) {
        createBoxPlot(chartRef.current, groupedData, tooltipRef.current);
      } else if (tabIndex === 1) {
        createScatterPlot(chartRef.current, scatterData, tooltipRef.current, maxDateWithMonthAdded);
      } else if (tabIndex === 2) {
        const savingsData = items.map(item => ({
          name: item.name,
          price: parseFloat(item.price) || 0,
          retailPrice: parseFloat(item.retailPrice) || 0,
        }));
        createSavingsPlot(chartRef.current, savingsData, tooltipRef.current);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [items, tabIndex, startDate, endDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMeasurements(prevMeasurements => ({
      ...prevMeasurements,
      [name]: value
    }));
  };

  const handleSaveMeasurements = () => {
    dispatch(updateMeasurements(measurements));
  };

  const measurementKeys = Object.keys(measurementLabels);

  return (
    <DataBox>
      <TabsContainer
        value={tabIndex}
        onChange={(e, newValue) => setTabIndex(newValue)}
        aria-label="chart tabs"
        centered
        textColor="secondary"
        indicatorColor="secondary"
      >
        <StyledTab label="Price Distribution" />
        <StyledTab label="Date vs. Amount Spent" />
        <StyledTab label="Savings" />
        <StyledTab label="Measurements" />
        <StyledTab label="Summary" />
      </TabsContainer>

      {tabIndex === 4 && (
        <SummaryContainer>
          <SummaryItem>Total Items: {totalItems}</SummaryItem>
          <SummaryItem>Total Cost: ${totalCost.toFixed(2)}</SummaryItem>
          <SummaryItem>Brands: {uniqueBrands.join(', ')}</SummaryItem>
        </SummaryContainer>
      )}

      {tabIndex === 0 && (
        <ChartContainer>
          <div ref={chartRef} style={{ width: '100%' }} />
          <Tooltip ref={tooltipRef} />
        </ChartContainer>
      )}
      {tabIndex === 1 && (
        <>
          <DatePickerContainer>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              dateFormat="MMM d, yyyy"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              dateFormat="MMM d, yyyy"
            />
          </DatePickerContainer>
          <ChartContainer>
            <div ref={chartRef} style={{ width: '100%' }} />
            <Tooltip ref={tooltipRef} />
          </ChartContainer>
        </>
      )}
      {tabIndex === 3 && measurements && (
        <MeasurementsContainer>
          {measurementKeys.map((key) => (
            <MeasurementItem key={key}>
              <label>{measurementLabels[key]}:</label>
              <input
                type="number"
                name={key}
                value={measurements[key]}
                onChange={handleInputChange}
              />
            </MeasurementItem>
          ))}
          <SaveButton variant="contained" color="primary" onClick={handleSaveMeasurements}>
            Save Measurements
          </SaveButton>
        </MeasurementsContainer>
      )}
      {tabIndex === 2 && (
        <ChartContainer>
          <div ref={chartRef} style={{ width: '100%' }} />
          <Tooltip ref={tooltipRef} />
        </ChartContainer>
      )}
    </DataBox>
  );
};

export default DataView;
