
import * as d3 from 'd3';

export function createScatterPlot(container, data, tooltip, maxDateWithMonthAdded) {
  d3.select(container).select('svg').remove();

  const margin = { top: 20, right: 30, bottom: 30, left: 40 };
  const width = container.offsetWidth - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;
  const svg = d3.select(container).append('svg')
    .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleTime()
    .domain([d3.min(data, d => d.date), maxDateWithMonthAdded])
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.price)])
    .range([height, 0]);

  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%b %Y')));

  svg.append('g')
    .call(d3.axisLeft(y));

  svg.selectAll('circle')
    .data(data)
    .enter()
    .append('circle')
    .attr('cx', d => x(d.date))
    .attr('cy', d => y(d.price))
    .attr('r', 5)
    .attr('fill', '#333')
    .on('mouseover', function (event, d) {
      d3.select(this).style('opacity', 0.5);
      tooltip.style.opacity = 1;
      tooltip.innerHTML = `${d.name}<br>${d.date.toDateString()}<br>$${d.price}`;
    })
    .on('mousemove', function (event) {
      const tooltipWidth = tooltip.offsetWidth;
      const tooltipHeight = tooltip.offsetHeight;
      const xPos = event.clientX - 100;
      const yPos = event.clientY - 100;

      // Adjust tooltip position if it goes beyond the screen limits
      const adjustedX = xPos + tooltipWidth > window.innerWidth ? window.innerWidth - tooltipWidth - 10 : xPos;
      const adjustedY = yPos + tooltipHeight > window.innerHeight ? window.innerHeight - tooltipHeight - 10 : yPos;

      tooltip.style.left = `${adjustedX}px`;
      tooltip.style.top = `${adjustedY}px`;
    })
    .on('mouseout', function () {
      d3.select(this).style('opacity', 1);
      if (tooltip) {
        tooltip.style.opacity = 0;
        tooltip.innerHTML = '';  // Clear the tooltip content
      }
    });

  // Calculate mean price
  const meanPrice = d3.mean(data, d => d.price);

  if (!isNaN(meanPrice)) {
    // Draw mean line
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', y(meanPrice))
      .attr('x2', width)
      .attr('y2', y(meanPrice))
      .attr('stroke', 'red')
      .attr('stroke-width', 1.5)
      .attr('stroke-dasharray', '4 2');

    // Add mean line label
    svg.append('text')
      .attr('x', width - 10)
      .attr('y', y(meanPrice) - 10)
      .attr('text-anchor', 'end')
      .attr('fill', 'red')
      .text(`Mean: $${meanPrice.toFixed(2)}`);
  }
}

export function createBoxPlot(container, data, tooltip) {
  d3.select(container).select('svg').remove();

  const margin = { top: 20, right: 30, bottom: 30, left: 40 };
  const width = container.offsetWidth - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;
  const svg = d3.select(container).append('svg')
    .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleBand()
    .domain(data.map((d, i) => i))
    .range([0, width])
    .padding(0.2);

  const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.price)])
    .range([height, 0]);

  svg.append('g')
    .call(d3.axisLeft(y));

  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat(''));

  // Add bars
  svg.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', (d, i) => x(i))
    .attr('y', d => y(d.price))
    .attr('width', x.bandwidth())
    .attr('height', d => height - y(d.price))
    .attr('fill', '#f0ece2')
    .attr('class', 'bar')
    .on('mouseover', function (event, d) {
      d3.select(this).style('opacity', 0.5);
      tooltip.style.opacity = 1;
      tooltip.innerHTML = d.names.join('<br><hr>');
    })
    .on('mousemove', function (event) {
      const tooltipWidth = tooltip.offsetWidth;
      const tooltipHeight = tooltip.offsetHeight;
      const xPos = event.clientX + 10;
      const yPos = event.clientY + 10;

      // Adjust tooltip position if it goes beyond the screen limits
      const adjustedX = xPos + tooltipWidth > window.innerWidth ? window.innerWidth - tooltipWidth - 10 : xPos;
      const adjustedY = yPos + tooltipHeight > window.innerHeight ? window.innerHeight - tooltipHeight - 10 : yPos;

      tooltip.style.left = `${adjustedX}px`;
      tooltip.style.top = `${adjustedY}px`;
    })
    .on('mouseout', function () {
      d3.select(this).style('opacity', 1);
      tooltip.style.opacity = 0;
      tooltip.innerHTML = '';  // Clear the tooltip content
    });

  // Add price labels above bars
  svg.selectAll('text.price-label')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'price-label')
    .attr('x', (d, i) => x(i) + x.bandwidth() / 2)
    .attr('y', d => y(d.price) - 5)
    .attr('text-anchor', 'middle')
    .attr('fill', '#333')
    .text(d => `$${d.price.toFixed(2)}`);

  // Calculate average price
  const averagePrice = d3.mean(data, d => d.price);

  if (!isNaN(averagePrice)) {
    // Draw average line
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', y(averagePrice))
      .attr('x2', width)
      .attr('y2', y(averagePrice))
      .attr('stroke', 'red')
      .attr('stroke-width', 1.5)
      .attr('stroke-dasharray', '4 2');

    // Add average line label
    svg.append('text')
      .attr('x', width - 10)
      .attr('y', y(averagePrice) - 10)
      .attr('text-anchor', 'end')
      .attr('fill', 'red')
      .text(`Average: $${averagePrice.toFixed(2)}`);
  }
}

export function createSavingsPlot(container, originalData, tooltip) {
  d3.select(container).select('svg').remove();

  const data = originalData.filter(item => item.price !== 0 && item.retailPrice !== 0);

  if (data.length === 0) {
    // If no items to display, show a message
    d3.select(container)
      .append('div')
      .attr('class', 'no-data-message')
      .style('text-align', 'center')
      .style('margin-top', '20px')
      .style('font-size', '16px')
      .text('This is only available for items with both price and retail price defined.');
    return;
  }

  const margin = { top: 20, right: 30, bottom: 50, left: 60 };
  const width = container.offsetWidth - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;
  const svg = d3.select(container).append('svg')
    .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleBand()
    .domain(data.map(d => d.name))
    .range([0, width])
    .padding(0.2);

  const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => Math.max(d.price, d.retailPrice))])
    .range([height, 0]);

  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-0.8em')
    .attr('dy', '0.15em')
    .attr('transform', 'rotate(-65)');

  svg.append('g')
    .call(d3.axisLeft(y));

  // Draw lines between points
  const line = d3.line()
    .x(d => x(d.name) + x.bandwidth() / 2)
    .y(d => y(d.price))
    .curve(d3.curveMonotoneX); // Smooth curve

  svg.append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'steelblue')
    .attr('stroke-width', 2)
    .attr('d', line);

  const lineRetail = d3.line()
    .x(d => x(d.name) + x.bandwidth() / 2)
    .y(d => y(d.retailPrice))
    .curve(d3.curveMonotoneX); // Smooth curve

  svg.append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-width', 2)
    .attr('d', lineRetail);

  // Add circles for price
  svg.selectAll('.circle-price')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'circle-price')
    .attr('cx', d => x(d.name) + x.bandwidth() / 2)
    .attr('cy', d => y(d.price))
    .attr('r', 5)
    .attr('fill', 'steelblue')
    .attr('stroke', 'white')
    .attr('stroke-width', 1.5)
    .on('mouseover', function (event, d) {
      d3.select(this).style('opacity', 0.7);
      tooltip.style.opacity = 1;
      tooltip.innerHTML = `Name: ${d.name}<br>Price: $${d.price}<br>Retail Price: $${d.retailPrice}`;
    })
    .on('mousemove', function (event) {
      tooltip.style.left = `${event.pageX + 10}px`;
      tooltip.style.top = `${event.pageY - 28}px`;
    })
    .on('mouseout', function () {
      d3.select(this).style('opacity', 1);
      tooltip.style.opacity = 0;
    });

  // Add circles for retail price
  svg.selectAll('.circle-retailPrice')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'circle-retailPrice')
    .attr('cx', d => x(d.name) + x.bandwidth() / 2)
    .attr('cy', d => y(d.retailPrice))
    .attr('r', 5)
    .attr('fill', 'black')
    .attr('stroke', 'white')
    .attr('stroke-width', 1.5)
    .on('mouseover', function (event, d) {
      d3.select(this).style('opacity', 0.7);
      tooltip.style.opacity = 1;
      tooltip.innerHTML = `Name: ${d.name}<br>Price: $${d.price}<br>Retail Price: $${d.retailPrice}`;
    })
    .on('mousemove', function (event) {
      tooltip.style.left = `${event.pageX + 10}px`;
      tooltip.style.top = `${event.pageY - 28}px`;
    })
    .on('mouseout', function () {
      d3.select(this).style('opacity', 1);
      tooltip.style.opacity = 0;
    });

  // Calculate total savings
  const totalSavings = data.reduce((acc, item) => acc + (item.retailPrice - item.price), 0);

  // Add total savings text
  svg.append('text')
    .attr('x', width / 1.1)
    .attr('text-anchor', 'middle')
    .attr('fill', 'black')
    .attr('font-size', '16px')
    .text(`Total Savings: $${totalSavings.toFixed(2)}`);
}
