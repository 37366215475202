import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import monadImg from '../../../assets/img/monad.png';
import { fadeIn } from '../../../helpers/styleUtil';

const LekondoImage = styled.img`
  width: 25%; // Adjust the width as necessary
  max-width: 220px;
  margin-bottom: 5%; // Spacing between the image and the text
`;

// Styled components
const StyledAboutContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.6s ease-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  color: black;
  font-size: 20px;
  padding: 20px;
  text-align: center;
`;

const ExternalLink = styled.a`
  color: blue;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Definition = styled.div`
width: 50%;
  font-size: 24px; // Slightly larger font for the definition
  margin-bottom: 20px; // Space before the navigation button
`;

const DefinitionDescription = styled.div`
text-align: left; // Align the text to the left
`;


const LinksContainer = styled.div`
  margin-top: 20px; // Space above the links
  font-size: 18px; // Slightly smaller font for the links
`;

const Link = styled(RouterLink)`
  color: blue;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const ContactEmail = styled.div`
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: black;
  text-align: center;
  margin-bottom: 35px;
`;

const AboutPage = () => {
  return (
    <StyledAboutContainer>
      <LekondoImage src={monadImg} alt="Loading Image" />
      <Definition>
        <strong>Lekondo</strong><br />
        (le-kon-doh)<br />
        <em>adj. informal</em><br /><br />
        <DefinitionDescription>
          Lekondo was built on the belief that all phenomena are projections created by the mind that appears to experience them. We understand that organizing your items can sometimes feel like a chore based on imaginary complexities.
          <br />
          <br />
          Using our platform, we hope to become a personal historian for your cherished belongings, providing detailed context on the items you have kept, updated, and discarded throughout your life.
          <br />
          <br />
          <hr />
        </DefinitionDescription>
      </Definition>
      <LinksContainer>
        <ContactEmail>contact@lekondo.com</ContactEmail>
        <ExternalLink href="https://discord.com/invite/G7WK5T7Pmg" target="_blank" rel="noopener noreferrer">Discord</ExternalLink>
        <span>|</span>
        <Link to="/about/terms-of-service">Terms of Service</Link>
        <span>|</span>
        <Link to="/about/privacy-policy">Privacy Policy</Link>
      </LinksContainer>
    </StyledAboutContainer >
  );
};

export default AboutPage;
