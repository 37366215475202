import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from './redux/store';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';


const container = document.getElementById('root');
const root = createRoot(container); // Create a root.
const googleClientID = `794268378666-l06fo9cqr4nm4fqgij6k1fsdss7idrcm.apps.googleusercontent.com`;

root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId={googleClientID}>
            <BrowserRouter>
                <App />
            </BrowserRouter>,

        </GoogleOAuthProvider>
    </Provider>
);
