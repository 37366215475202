/* eslint-disable no-case-declarations */
import { toast } from 'react-toastify';
import { serverUrl } from '../../constants/urls';
import initializeSocket from './socket';
import { userLoading } from '../actions/index';

let socket;
let middlewareTriggered = false; // Flag to check if the middleware triggered the action

const reduxActionsTriggerSaveDiscovery = ['ADD_LIKE', 'REMOVE_LIKE', 'ADD_COMMENT', 'EDIT_COMMENT', 'DELETE_COMMENT'];

export const saveDiscoveryStateMiddleware = store => next => action => {
  if (!socket) {
    socket = initializeSocket(store);
  }

  if (middlewareTriggered) {
    // If the middleware triggered this action, reset the flag and continue without causing side effects
    middlewareTriggered = false;
    return next(action);
  }

  const result = next(action);
  const newState = store.getState();

  try {
    const serializedState = JSON.stringify(newState);
    localStorage.setItem('appState', serializedState);
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      console.error('Error: Local storage limit exceeded. Consider optimizing the data or using a different storage method.');
    } else {
      console.error('Error saving to local storage:', error);
    }
  }

  const isUserLoggedIn = newState.user.email;

  if (isUserLoggedIn) {
    if (reduxActionsTriggerSaveDiscovery.includes(action.type)) {
      middlewareTriggered = true; // Set the flag before dispatching any action that could loop back
      store.dispatch(userLoading(true));

      const { fitId, fitOwnerEmail, update } = extractUpdateDetails(action, newState);

      console.log(update)
      if (!update) {
        store.dispatch(userLoading(false));
        return result;
      }

      fetch(`${serverUrl}/api/discovery/saveState`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fitOwnerEmail,
          fitId,
          update
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log('Discovery state saved successfully:', data);
          // Emit a WebSocket event to notify other clients
          if (socket) {
            socket.emit('discoveryStateUpdated', {
              user: newState.user,
              discovery: newState.discovery
            });
          } else {
            console.error('Socket is not initialized');
            store.dispatch(userLoading(false));
          }
          store.dispatch(userLoading(false));
          toast.success('Updated successfully!');
        }).catch(error => {
          store.dispatch(userLoading(false));
          toast.error('Save failed');
          console.log('Error:', error);
        })
        .finally(() => {
          middlewareTriggered = false; // Reset the flag after the async operation completes
        });
    }
  }

  return result;
};

const extractUpdateDetails = (action, state) => {
  const { fitId, fitOwnerEmail, userEmail = null } = action.payload;
  const user = state.discovery.users.find(user => user.email === fitOwnerEmail);
  const fit = user ? user.fits.find(fit => fit.id === fitId) : null;

  if (!fit) {
    return { fitId, fitOwnerEmail, update: null };
  }

  let update = { ...fit };

  switch (action.type) {
    case 'ADD_COMMENT':
      const commentExists = fit.comments.some(comment =>
        comment.text === action.payload.comment.text &&
        comment.author === action.payload.comment.author
      );

      if (!commentExists) {
        update.comments = [...fit.comments, action.payload.comment];
      }
      break;

    case 'EDIT_COMMENT':
      update.comments = fit.comments.map((comment, index) =>
        index === action.payload.index ? { ...comment, text: action.payload.newText } : comment
      );
      break;

    case 'DELETE_COMMENT':
      update.comments = fit.comments.filter((_, index) => index !== action.payload.index);
      break;

    case 'ADD_LIKE':
      const alreadyLiked = fit.likes.some(like => like.email === userEmail);

      if (!alreadyLiked) {
        update.likes = [...fit.likes, { email: userEmail, author: action.payload.author }];
      }
      break;

    case 'REMOVE_LIKE':
      update.likes = fit.likes.filter(like => like.email !== userEmail);
      break;

    default:
      break;
  }

  if (update) {
    console.log('Update needed:', update);
  } else {
    console.log('No update needed');
  }

  return { fitId, fitOwnerEmail, update };
};
