
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoAdd } from "react-icons/io5";
import { BsArrowsCollapse } from "react-icons/bs";
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Mixpanel from '../helpers/mixpanel';

import SearchResults from './Search/SearchResults';
import { serverUrl } from '../constants/urls';
import UploadEntry from './Modals/UploadEntry';
import UpgradeModal from './Modals/UpgradeModal';
import InventoryTabs from './InventoryView/InventoryTabs';
import InventoryItems from './InventoryItems';
import SortOptions from './InventoryView/SortOptions';
import Search from './InventoryView/Search';
import ItemForm from './InventoryView/ItemForm';
import { filterItemsByAttributesAndCategories } from '../helpers/util';
import { useAnalyticsEventTracker } from '../analytics';
import { setSelectedShelf, setViewEditMode, userLoading, addItem } from '../redux/actions';

const InventoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: background-color 0.3s, border 0.3s;
`;

const InventoryContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 70%;
  height: 100%;
  overflow-y: auto;
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 10px;
  margin-top: 15px;
  opacity: ${props => props.isClicked ? '0.3' : '1'};
  &:hover {
    transform: scale(1.1);
    background-color: #333;
  }
  text-align: center;
  font-size: 25px;

`;

const SearchAndToggle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchResultsWrapper = styled.div`
  width: 95%;
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px; // Gap between results

  @media (max-width: 768px) {
    padding: 10px; // Less padding for mobile
    gap: 15px;
  }
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #f2f2f2;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  font-family: "Outfit", sans-serif;
  text-transform: uppercase;

  &:hover {
    background-color: #e6e6e6;
  }

  svg {
    margin-left: 10px;
    font-size: 1.2em;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9em;
  }
`;
const Inventory = () => {
    const gaEventTracker = useAnalyticsEventTracker('Inventory');
    const dispatch = useDispatch();
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const visitingUserDetails = useSelector(state => state.visitingUserDetails.isVisiting);
    const user = useSelector(state => state.user);
    const isMobileView = useSelector(state => state.user.isMobileView);
    const inventory = useSelector(state => state.inventory);
    const items = useSelector(state => state.inventory.items);
    const selectedAttributes = useSelector(state => state.inventory.selectedAttributes);
    const selectedShelf = useSelector(state => state.inventory.selectedShelf);
    const isItemFormOpen = useSelector(state => state.inventory.isItemFormOpen);

    const { email, subscription: { userType } } = user;

    const [animateItems, setAnimateItems] = useState(true);
    const [filteredItems, setFilteredItems] = useState(items);
    const [isIconClicked, setIsIconClicked] = useState(false);
    const [sortOption, setSortOption] = useState('newToOld');

    const isFreemium = userType === 'postponed for now' && items.length > 15;

    useEffect(() => {
        let newFilteredItems = filterItemsByAttributesAndCategories(
            items, inventory.tagsIndex, inventory.brandsIndex, inventory.categoriesIndex, selectedAttributes
        );

        if (selectedShelf) {
            newFilteredItems = newFilteredItems.filter(item =>
                (item.shelves || []).some(shelf => shelf.id === selectedShelf)
            );
        } else {
            newFilteredItems = newFilteredItems.filter(item =>
                !(item.shelves || []).some(shelf => shelf.id === 'fits')
            );
        }

        setFilteredItems(newFilteredItems);

    }, [items, inventory, selectedAttributes, selectedShelf, dispatch]);

    const sortedItems = useMemo(() => {
        let sorted = [...filteredItems];
        switch (sortOption) {
            case 'oldToNew':
                sorted.sort((a, b) => new Date(a.listingDate) - new Date(b.listingDate));
                break;
            case 'newToOld':
                sorted.sort((a, b) => new Date(b.listingDate) - new Date(a.listingDate));
                break;
            case 'lowToHigh':
                sorted.sort((a, b) => a.price - b.price);
                break;
            case 'highToLow':
                sorted.sort((a, b) => b.price - a.price);
                break;
            case 'byBrand':
                sorted.sort((a, b) => {
                    const hasBrandA = a.brands && a.brands.length > 0;
                    const hasBrandB = b.brands && b.brands.length > 0;

                    if (!hasBrandA && hasBrandB) return 1;
                    if (hasBrandA && !hasBrandB) return -1;

                    return (a.brands[0] || '').localeCompare(b.brands[0] || '');
                });
                break;
            case 'byCategory':
                sorted.sort((a, b) => {
                    const hasCategoryA = a.categories && a.categories.length > 0;
                    const hasCategoryB = b.categories && b.categories.length > 0;

                    if (!hasCategoryA && hasCategoryB) return 1;
                    if (hasCategoryA && !hasCategoryB) return -1;

                    return (a.categories[0] || '').localeCompare(b.categories[0] || '');
                });
                break;
            default:
                sorted.sort((a, b) => Number(b.lastUpdated) - Number(a.lastUpdated));
        }
        return sorted;
    }, [filteredItems, sortOption]);

    const handleItemAdded = (addedItem) => {
        setSearchResults([]); // Clear search results
        toast.success(`${addedItem.name} has been added to your wardrobe!`);
    };

    const handleItemClick = (item) => {
        dispatch(setViewEditMode(false));
        setAnimateItems(false);
        gaEventTracker('Click', item.name);
    };

    const handleIconClick = () => {
        setToggleDropDown(!toggleDropDown);
        dispatch(setSelectedShelf('all'));
        setIsIconClicked(!isIconClicked);
    };

    const handleSearch = async (query) => {
        dispatch(userLoading(true));
        try {
            const serverResponse = await fetch(`${serverUrl}/api/queryProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    email: user.email
                }),
            });

            const data = await serverResponse.json();

            if (data.success) {
                setSearchResults(data.productsFromQuery);
            } else {
                toast.error('Failed to fetch search results. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            toast.error('Failed to fetch search results. Please try again.');
        } finally {
            dispatch(userLoading(false));
        }
    };

    const fetchItemDetailsFromUrl = async (url) => {
        dispatch(userLoading(true));
        try {
            const serverResponse = await fetch(`${serverUrl}/api/scrape-and-save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    linkFromWebsite: url,
                }),
            });

            const { scrapedItem } = await serverResponse.json();

            if (scrapedItem.id) {
                const item = { ...scrapedItem, isItemScraped: true };
                dispatch(addItem(item));
                dispatch(userLoading(false));
                Mixpanel.track('Item Scraped', {
                    userEmail: email,
                    itemId: item.id,
                });
            }
        } catch (error) {
            dispatch(userLoading(false));
            toast.error('Failed to fetch item details');
            console.error('Failed to fetch item details:', error);
            Mixpanel.track('Item Scrape Failed', {
                userEmail: email,
                error: error.message,
            });
        }
    };

    const handleSearchSubmit = (input, type) => {
        if (type === 'url') {
            fetchItemDetailsFromUrl(input);
        } else {
            handleSearch(input);
        }
    };

    return (
        <InventoryWrapper>
            <SearchAndToggle>
                {!visitingUserDetails && (
                    <>
                        <AddButton onClick={handleIconClick}>
                            <IoAdd />
                        </AddButton>
                    </>
                )}
                {!visitingUserDetails && (

                    <Search onSubmit={handleSearchSubmit} />

                )}
                <SortOptions user={user} sortOption={sortOption} setSortOption={setSortOption} />
            </SearchAndToggle>
            {toggleDropDown && (
                isFreemium ? (
                    <UpgradeModal isOpen={true} onClose={() => setToggleDropDown(false)} />
                ) : (
                    <UploadEntry onClose={() => setToggleDropDown(false)} />
                )
            )}
            {isItemFormOpen && <ItemForm />}
            {<InventoryTabs />}
            <InventoryContainer>
                {searchResults.length > 0 && (
                    <SearchResultsWrapper isMobileView={isMobileView}>
                        {<SearchResults results={searchResults} onItemAdded={handleItemAdded} />}
                        <ToggleButton onClick={() => setSearchResults([])}>
                            <BsArrowsCollapse />
                        </ToggleButton>
                    </SearchResultsWrapper>
                )}
                <InventoryItems
                    sortedItems={sortedItems}
                    animateItems={animateItems}
                    handleItemClick={handleItemClick}
                />
            </InventoryContainer>
        </InventoryWrapper>
    );
};

export default Inventory;
