import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoArrowSwitch } from "react-icons/go";
import styled from 'styled-components';
import ViewMode from './FitPicView';
import EditMode from './FitPicEdit';
import Mixpanel from '../../../helpers/mixpanel';

import { serverUrl } from '../../../constants/urls';

import { setCurrentItem, deleteItem, toggleItemForm, updateItem } from '../../../redux/actions';

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px; /* Add some space between buttons and content */
`;

const ReverseButton = styled(GoArrowSwitch)`
  font-size: 50px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.5;
    color: #0056b3;
  }
`;

const ShareButton = styled.button`
  width: auto;
  padding: 10px 30px;
  background-color: #fff;
  margin-right: 20px;
  color: #000;
  border: 1px solid #000;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

const DiscoverButton = styled.button`
  width: auto;
  padding: 10px 30px;
  background-color: #000;
  margin-right: 20px;
  color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const aggregateLinkedItemsAttributes = (item, allItems) => {
  const linkedItems = item?.linkedItems?.map(link => allItems.find(i => i.id === link.itemId)).filter(Boolean) || [];

  const aggregatedAttributes = linkedItems.reduce((acc, linkedItem) => {
    acc.brands = [...new Set([...acc.brands, ...linkedItem.brands])];
    acc.categories = [...new Set([...acc.categories, ...linkedItem.categories])];
    acc.tags = [...new Set([...acc.tags, ...linkedItem.tags])];
    acc.price += linkedItem.price || 0;
    acc.retailPrice += linkedItem.retailPrice || 0;
    acc.sources = [...new Set([...acc.sources, linkedItem.source?.fromWebsiteUrl].filter(Boolean))];
    return acc;
  }, {
    brands: [],
    categories: [],
    tags: [],
    price: 0,
    retailPrice: 0,
    sources: []
  });

  return aggregatedAttributes;
};

const FitPicScreen = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState(item);
  const [isEditMode, setIsEditMode] = useState(false);
  const items = useSelector(state => state.inventory.items);
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const userDetails = useSelector(state => state.user);

  const aggregatedAttributes = useMemo(() => aggregateLinkedItemsAttributes(itemDetails, items), [itemDetails, items]);

  useEffect(() => {
    Mixpanel.track('Fit Pic Viewed', {
      picId: itemDetails.id,
      userEmail: userDetails.email
    });
  }, [itemDetails.id, userDetails.email]);

  const handleLinkedItemClick = (itemId) => {
    const linkedItem = items.find(item => item.id === itemId);
    if (linkedItem) {
      dispatch(setCurrentItem(linkedItem));
    }
  };

  const onSave = () => {
    dispatch(updateItem(itemDetails));
    dispatch(toggleItemForm(false));
  }

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteItem(itemDetails.id));
    dispatch(toggleItemForm(false));
  };

  const toggleMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDiscover = () => {
    navigate('/discover')
  };


  const handleShare = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const emailForSharing = visitingUserDetails?.isVisiting ?
      visitingUserDetails.email :
      userDetails.email;

    try {
      const response = await fetch(`${serverUrl}/api/share`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: emailForSharing, itemId: itemDetails.id })
      });
      const data = await response.json();
      navigator.clipboard.writeText(data.shareableLink);
      toast.success('Link copied to clipboard!');
      Mixpanel.track('Fit Pic Shared', {
        picId: itemDetails.id,
        userEmail: userDetails.email
      });
    } catch (error) {
      console.error('Error sharing item:', error);
      toast.error('Failed to generate share link for item');
    }
  };
  return (
    <Container>
      <ButtonContainer>
        {!visitingUserDetails?.isVisiting && <ReverseButton onClick={toggleMode} />}
        <ShareButton onClick={handleShare}>Share</ShareButton>
        <DiscoverButton onClick={handleDiscover}>Discover</DiscoverButton>
      </ButtonContainer>
      {isEditMode ? (
        <EditMode
          itemDetails={itemDetails}
          setItemDetails={setItemDetails}
          onSave={onSave}
          handleDelete={handleDelete}
        />
      ) : (
        <ViewMode
          itemDetails={itemDetails}
          aggregatedAttributes={aggregatedAttributes}
          items={items}
          handleLinkedItemClick={handleLinkedItemClick}
        />
      )}
    </Container >
  );
};

export default FitPicScreen;
