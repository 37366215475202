/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../helpers/styleUtil';

const Container = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  padding: 10px;
  margin-bottom: 2%;
  font-size: 18px;
  line-height: 1.6;
  overflow-y: auto;
  width: 100%;
  height: 90vh;
`;

const TermsOfService = () => {
  return (
    <Container>
      <h1>Lekondo Terms of Service</h1>
      <p>These Terms of Service (&quot;Terms&quot;) govern your access to and use of the Lekondo website located at lekondo.com (the &quot;Website&quot;) and the services offered by Lekondo (collectively, the &quot;Service&quot;).</p>
      <h2>Acceptance of Terms</h2>
      <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the Service.</p>
      <h2>Eligibility</h2>
      <p>You must be at least 13 years old to access or use the Service. By accessing or using the Service, you represent and warrant that you are at least 13 years old and of legal capacity to enter into this agreement.</p>
      <h2>User Accounts</h2>
      <p>You may need to create an account to access certain features of the Service. You are responsible for maintaining the confidentiality of your account information, including your password. You are also responsible for all activities that occur under your account.</p>
      <h2>User Content</h2>
      <p>You are responsible for all content that you upload, post, or otherwise transmit through the Service ("User Content"). You retain all ownership rights to your User Content. However, by submitting User Content, you grant Lekondo a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, distribute, and translate your User Content for the purpose of operating and providing the Service.</p>
      <h2>Prohibited Conduct</h2>
      <p>You agree not to use the Service for any unlawful purpose or in any manner that violates these Terms. You agree not to:</p>
      <ul>
        <li>Upload, post, or transmit any User Content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.</li>
        <li>Interfere with or disrupt the Service or the servers or networks that support the Service.</li>
        <li>Violate the intellectual property rights of others.</li>
        <li>Impersonate any person or entity.</li>
        <li>Collect or store personal information about other users without their express consent.</li>
      </ul>
      <h2>Termination</h2>
      <p>Lekondo may terminate your access to the Service at any time, for any reason, with or without notice.</p>
      <h2>Disclaimer of Warranties</h2>
      <p>THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. LEKONDO DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE.</p>
      <h2>Limitation of Liability</h2>
      <p>LEKONDO SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICE.</p>
      <h2>Indemnification</h2>
      <p>You agree to indemnify and hold harmless Lekondo, its officers, directors, employees, agents, and licensors from and against any and all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or relating to your use of the Service or your violation of these Terms.</p>
      <h2>Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of New Zealand, without regard to its conflict of laws provisions.</p>
      <h2>Dispute Resolution</h2>
      <p>Any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of the New Zealand Arbitration Association. The arbitration shall be held in Auckland, New Zealand.</p>
      <h2>Entire Agreement</h2>
      <p>These Terms constitute the entire agreement between you and Lekondo regarding your use of the Service.</p>
      <h2>Severability</h2>
      <p>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.</p>
      <h2>Updates to Terms of Service</h2>
      <p>Lekondo may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the Website. You are advised to review these Terms periodically for any changes. Your continued use of the Service after the posting of any revised Terms constitutes your acceptance of such revised Terms.</p>
    </Container>
  );
};

export default TermsOfService;
