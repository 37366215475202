import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CiCircleMinus } from "react-icons/ci";
// import { TbRotateRectangle } from "react-icons/tb";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { ImageList, ImageListItem, Modal, Box, IconButton } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import addItemImage from '../../assets/img/addItem.png';
import { userLoading, toggleItemForm, addItem, updateItem, setViewEditMode, deleteItem } from '../../redux/actions';
import ItemView from './itemForm/ItemView';
import ItemEdit from './itemForm/ItemEdit';
import { serverUrl } from '../../constants/urls';

const ExitButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100;
  &:hover {
    color: black;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  display: block;
  margin: 0 auto;
`;

const ItemFormModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;


const FormContent = styled.div`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  gap: 30px;
  overflow:auto;
  align-items: stretch; /* Ensure children stretch to the full height */
`;

const FormImageContainer = styled.div`
  position: relative;
  flex: ${({ isMobileView }) => (isMobileView ? '0' : '1 45%')};
  margin-bottom: 5%;
  overflow-y: ${({ isMobileView }) => (isMobileView ? 'none' : 'auto')};
  height: 100%;
  max-height: ${({ isMobileView }) => (isMobileView ? '' : '75vh')};
  text-align: center;
`;

const FormFieldsContainer = styled.div`
  flex: ${({ isMobileView }) => (isMobileView ? '1' : '1 55%')};
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure children are spaced out */
  overflow-x: hidden;
`;

const FormImage = styled.img`
  margin: auto;
  width: 90%;
  min-width: 100px;
  min-height: 100px;
  max-height: 100%;
  border-radius: 8px;
  object-fit:cover;
  cursor: pointer;
  outline: ${({ editMode }) => (editMode ? '2px dashed #8b0000' : '')};
  outline-offset: ${({ editMode }) => (editMode ? '10px' : '')};   
  &:hover {
    opacity: ${({ editMode }) => (editMode ? '1' : '0.85')};
  }
`;

const FormImageAdd = styled.img`
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Form = styled.form`
  width: 75%;
  height: 70%;
  max-width: ${({ editMode }) => (editMode ? '90%' : '80%')};
  max-height: 80vh;
  background: white;
  margin: 5%;
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
  overflow: ${({ isMobileView }) => (isMobileView ? 'auto' : 'hidden')};;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  z-index: 50;
  pointer-events: none;
`;

const DeleteImageButton = styled(CiCircleMinus)`
  position: absolute;
  font-size: 20px;
  top: 10%;
  right: 50px;
  background-color: #8b0000;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  &:hover {
    opacity: 0.5;
  }
`;

// Update the Scroll behavior for the Modal
const ScrollableModalContent = styled.div`
  overflow-y: auto;
  max-height: 90vh;
  padding: 20px;
`;


const ItemForm = ({ placeholderImage }) => {
  const item = useSelector(state => state.inventory.currentItem);
  const userDetails = useSelector(state => state.user);
  const viewEditMode = useSelector(state => state.inventory.viewEditMode);
  const isMobileView = useSelector(state => state.user.isMobileView);
  const [outsideClicks, setOutsideClicks] = useState(0);
  const [itemDetails, setItemDetails] = useState(item);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState(item?.images || [placeholderImage]);

  const [onSwitch, setOnSwitch] = useState(viewEditMode);
  const handleOnSwitch = () => {
    setOnSwitch(!onSwitch);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNext = () => setCurrentImage((prev) => (prev + 1) % images.length);
  const handleBack = () => setCurrentImage((prev) => (prev - 1 + images.length) % images.length);

  const dispatch = useDispatch();
  const formRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      function isInsideProtectedArea(element) {
        return element.closest('.MuiMenu-root, .MuiPopover-root, #formImageContainer, #modalContainer, .slider-arrow') !== null;
      }

      if (formRef.current && !formRef.current.contains(event.target) && !isInsideProtectedArea(event.target)) {
        setOutsideClicks(prev => prev + 1);
        if (outsideClicks >= 1) {
          dispatch(toggleItemForm(false));
        }
      } else {
        setOutsideClicks(0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [outsideClicks]);

  useEffect(() => {
    if (item?.isItemScraped) {
      setItemDetails({ ...item, isItemScraped: false });
      dispatch(addItem(({ ...item, isItemScraped: false })));
    }
  }, [item, dispatch]);

  useEffect(() => {
    setItemDetails(item || {});
    setImages(item?.images.length > 0 ? item.images : [placeholderImage]);
  }, [item, placeholderImage]);

  const handleSubmit = (event) => {
    const updatedItemDetails = {
      ...itemDetails,
      images
    };
    event.preventDefault();
    if (viewEditMode) {
      dispatch(updateItem(updatedItemDetails));
      dispatch(toggleItemForm(false));
    } else {
      dispatch(setViewEditMode(true));
    }
  };

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteItem(itemDetails.id));
    dispatch(toggleItemForm(false));
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('images', file);
    });
    formData.append('email', userDetails.email);

    try {
      dispatch(userLoading(true));
      const response = await fetch(`${serverUrl}/api/uploadImages`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setImages(prevImages => [...prevImages, ...data.urls]);
        dispatch(userLoading(false));
      } else {
        dispatch(userLoading(false));
        console.error('Failed to upload images');
      }
    } catch (error) {
      dispatch(userLoading(false));
      console.error('Error uploading images:', error);
    }
  };

  const handleRemoveImage = (index, event) => {
    event.stopPropagation();
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedImages = Array.from(images);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);
    setImages(reorderedImages);
  };

  return (
    <ItemFormModal>
      {outsideClicks >= 1 && <Overlay>Click Again to Close</Overlay>}
      <ExitButton onClick={() => dispatch(toggleItemForm(false))}>✕</ExitButton>
      <Form ref={formRef} editMode={viewEditMode} isMobileView={isMobileView}>
        <ScrollableModalContent>
          <FormContent isMobileView={isMobileView} >
            <FormImageContainer id="formImageContainer" isMobileView={isMobileView}>
              {viewEditMode ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="images">
                    {(provided) => (
                      <ImageList cols={1} {...provided.droppableProps} ref={provided.innerRef}>
                        <StyledImageListItem key="addImage">
                          <FormImageAdd
                            onClick={triggerFileInput}
                            src={addItemImage}
                            alt="Add new item"
                          />
                        </StyledImageListItem>
                        {images.map((image, index) => (
                          <Draggable key={index} draggableId={`image-${index}`} index={index}>
                            {(provided) => (
                              <StyledImageListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => { handleOpen(); setCurrentImage(index); }}
                              >
                                <FormImage
                                  editMode={viewEditMode}
                                  src={image}
                                />
                                <DeleteImageButton onClick={(event) => handleRemoveImage(index, event)} />
                              </StyledImageListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ImageList>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <ImageList cols={1}>
                  {images.map((image, index) => (
                    <StyledImageListItem key={index} onClick={() => { handleOpen(); setCurrentImage(index); }}>
                      <FormImage
                        editMode={viewEditMode}
                        src={image}
                      />
                    </StyledImageListItem>
                  ))}
                </ImageList>
              )}
              <Modal
                id="modalContainer"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '65%', height: '85%', bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: 24 }}>
                  <IconButton className="slider-arrow" onClick={handleBack} sx={{ position: 'absolute', left: 15, top: '50%', transform: 'translateY(-50%)' }}>
                    <IoIosArrowRoundBack />
                  </IconButton>
                  <img
                    src={images[currentImage]}
                    alt={`Image ${currentImage}`}
                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                  />
                  <IconButton className="slider-arrow" onClick={handleNext} sx={{ position: 'absolute', right: 15, top: '50%', transform: 'translateY(-50%)' }}>
                    <IoIosArrowRoundForward />
                  </IconButton>
                </Box>
              </Modal>
            </FormImageContainer>
            <FormFieldsContainer isMobileView={isMobileView}>
              {viewEditMode ? (
                <ItemEdit onSwitch={handleOnSwitch} itemDetails={itemDetails} setItemDetails={setItemDetails} handleDelete={handleDelete} onSave={handleSubmit} />
              ) : (
                <ItemView onSwitch={handleOnSwitch} itemDetails={itemDetails} onSubmit={handleSubmit} />
              )}
            </FormFieldsContainer>
          </FormContent>
        </ScrollableModalContent>
        <input type="file" multiple ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageChange} />
      </Form>
    </ItemFormModal>
  );
};

export default ItemForm;