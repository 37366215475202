export const tags = {
  "tags": [
    "summer",
    "winter",
    "spring",
    "autumn",
    "gorpcore",
    "streetwear",
    "minimalist",
    "techwear",
    "bohemian",
    "capsule",
    "slow",
    "athleisure",
    "normcore",
    "futuristic",
    "vintage",
    "cosplay",
    "artisanal",
    "hygge",
    "luxury",
    "punk",
    "cottagecore",
    "sustainable",
    "avant-garde",
    "retro",
    "monochrome",
    "urban",
    "casual chic",
    "formal",
    "preppy",
    "nautical",
    "military",
    "industrial",
    "modern",
    "classic",
    "layering",
    "functional",
    "comfort",
    "seasonal",
    "transitional",
    "handmade",
    "limited edition",
    "designer",
    "collaboration",
    "exclusive",
    "trendy",
    "street",
    "eclectic",
    "pop culture",
    "gender fluid"
  ]
}
