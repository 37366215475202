import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AboutPage from './components/Footer/About/AboutDisplay';
import TermsOfService from './components/Footer/About/TermsOfService';
import PrivacyPolicy from './components/Footer/About/PrivacyPolicy';
import SettingsPage from './components/Settings/SettingsPage';
import DataView from './components/Footer/Data/DataView';
import DiscoverPage from './components/Discover/DiscoverPage';
import SharedWardrobeHandler from './components/Shared/SharedWardrobeHandler';
import LandingPage from './components/Landing/LandingPage';
import Logout from './components/Footer/Logout';
import Login from './components/Footer/Login';
import ConfirmationScreen from './components/Modals/ConfirmationScreen';

import AppContent from './AppContent';
import { setCompleteItems, setShelves, clearVisitingUserDetails } from './redux/actions';

const AppRoutes = () => {
  const doesUserExist = useSelector(state => state.user.email);
  useExitVisitorMode();

  return (
    <Routes>
      <Route path="/" element={doesUserExist ? <AppContent /> : <LandingPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/about/terms-of-service" element={<TermsOfService />} />
      <Route path="/about/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/confirmation-screen" element={<ConfirmationScreen />} />
      <Route path="/shared-wardrobe" element={<SharedWardrobeHandler />} />
      {doesUserExist ? (
        <>
          <Route path="/data" element={<DataView />} />
          <Route path="/discover" element={<DiscoverPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </>
      ) : (
        <>
          <Route path="/discover" element={<DiscoverPage />} />
          <Route path="*" element={<Navigate to="/logout" />} />
        </>
      )}
    </Routes>
  );
};
export default AppRoutes;


const useExitVisitorMode = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const storedUserItems = useSelector(state => state.user.items);
  const storedUserShelves = useSelector(state => state.user.shelves);
  useEffect(() => {
    const allowedPaths = ['/about', '/shared-wardrobe', '/about/terms-of-service', '/about/privacy-policy', '/discover'];

    if (visitingUserDetails.isVisiting && !allowedPaths.includes(location.pathname)) {
      dispatch(setCompleteItems(storedUserItems));
      dispatch(setShelves(storedUserShelves));
      dispatch(clearVisitingUserDetails());
    }
  }, [dispatch, location.pathname, visitingUserDetails, storedUserItems]);
};