import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowsCollapseVertical } from "react-icons/bs";
import { PiTrashSimpleDuotone } from "react-icons/pi";

import { setSelectedShelf, addShelf, renameShelf, deleteShelf } from '../../redux/actions';
import styled, { keyframes } from 'styled-components';

// Subtle fade-in effect for the modal
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Full-screen modal overlay
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-in-out;
  z-index: 1000;
`;

// Modal content
const ModalContent = styled.div`
  background: transparent;
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  padding: 20px;
  text-align: center;
  min-width: 300px;
  max-width: 400px;
`;

// Input field in the modal
const ModalInput = styled.input`
  border: none;
  border-bottom: 2px solid #fff;
  background: transparent;
  color: white;
  width: 100%;
  padding: 10px;
  font-size: 20px;
  text-align: center;

  &:focus {
    outline: none;
    border-color: #ccc;
  }

  &::placeholder {
    color: #ccc;
  }
`;

// Close button for the modal
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

// Add button in the modal
const ModalButton = styled.div`
  margin-top: 20px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  text-decoration: underline;

  &:hover {
    color: #ccc;
  }
`;

// Vertical Tab container on the right side of the screen
const VerticalTabContainer = styled.div`
  position: fixed;
  top: 10%;
  right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 3px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  max-height: 60vh;
  overflow-y: auto;
  z-index: 1000;
    width: ${({ collapsed }) => (collapsed ? '15px' : '200px')}; /* Adjust width based on collapsed state */
`;

// Style for each vertical tab
const VerticalTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? '#fff' : '#1a1a1a')};
  color: ${({ isActive }) => (isActive ? '#000' : '#ccc')};
  border-radius: 3px;
  margin-bottom: 10px;
  transition: background 0.3s, color 0.3s, width 0.3s ease;
  position: relative;

  &:hover {
    width: 100%; /* Shrinks to 80% when hovered */
    background: #ccc;
    color: #000;

    & > .delete-icon {
      opacity: 1; /* Shows delete icon */
      width: 20%; /* Expand delete icon to fill the remaining 20% */
    }
  }

  input {
    border: none;
    background: transparent;
    color: inherit;
    font-size: inherit;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    &:focus {
      outline: none;
    }
  }
`;

const DeleteIcon = styled.div`
  font-size: 16px;
  cursor: pointer;
  opacity: 0;
  width: 0; /* Hidden initially */
  transition: opacity 0.3s, width 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;

`;

// Button for adding a new shelf
const AddShelfButton = styled.div`
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px dashed #ccc;
  margin-top: 20px;

  &:hover {
    background: #fff;
    color: #000;
  }
`;

const InventoryTabs = () => {
  const dispatch = useDispatch();
  const shelves = useSelector((state) => state.inventory.shelves);
  const selectedShelf = useSelector((state) => state.inventory.selectedShelf);
  const isVisitor = useSelector(state => state.visitingUserDetails.isVisiting);
  const isItemFormOpen = useSelector(state => state.inventory.isItemFormOpen);

  const [shelfToDelete, setShelfToDelete] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newShelfName, setNewShelfName] = useState('');
  const [editingShelf, setEditingShelf] = useState(null);
  const [editingShelfName, setEditingShelfName] = useState('');

  useEffect(() => {
    if (!shelves.includes(selectedShelf)) {
      dispatch(setSelectedShelf(null)); // Ensure default selection
    }
  }, [shelves, selectedShelf, dispatch]);

  useEffect(() => {
    if (isItemFormOpen) {
      setIsCollapsed(true);
    }
  }, [isItemFormOpen])

  // Handle tab click to switch between shelves
  const handleTabClick = (shelfId) => {
    dispatch(setSelectedShelf(shelfId));
    setEditingShelf(null);
  };


  // Delete shelf
  const handleDeleteShelf = (shelfId) => {
    if (!isVisitor) {
      setShelfToDelete(shelfId);
    }
  };
  // Open modal to add shelf
  const handleAddShelf = () => {
    setIsCollapsed(true);
    setIsModalOpen(true);
  };

  // Close modal function
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewShelfName('');
  };

  // Confirm adding a new shelf
  const handleConfirmAdd = () => {
    if (newShelfName && !shelves.includes(newShelfName)) {
      dispatch(addShelf(newShelfName));
      dispatch(setSelectedShelf(newShelfName));
      setNewShelfName('');
      handleCloseModal();
    }
  };

  // Edit shelf on double click
  const handleDoubleClick = (shelfId) => {
    setEditingShelf(shelfId);
    setEditingShelfName(shelfId);
  };

  // Rename shelf
  const handleRenameShelf = () => {
    if (!isVisitor) {
      if (editingShelfName === '') {
        dispatch(deleteShelf(editingShelf));
      } else if (editingShelfName && editingShelf !== editingShelfName) {
        dispatch(renameShelf(editingShelf, editingShelfName));
      }
      setEditingShelf(null);
    }
  };

  const handleConfirmDelete = () => {
    if (shelfToDelete) {
      dispatch(deleteShelf(shelfToDelete));
      setShelfToDelete(null); // Close modal
    }
  };

  // Function to close delete modal
  const handleCloseDeleteModal = () => {
    setShelfToDelete(null);
  };

  // Close modal on outside click
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  // Close modal on escape key press
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        handleCloseModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <>
      {/* Modal for adding a new shelf */}
      {isModalOpen && (
        <ModalOverlay onClick={handleOverlayClick}>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
            <ModalInput
              type="text"
              placeholder="New Shelf Name"
              value={newShelfName}
              onChange={(e) => setNewShelfName(e.target.value)}
            />
            <ModalButton onClick={handleConfirmAdd}>Add</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      {shelfToDelete && (
        <ModalOverlay onClick={handleOverlayClick}>
          <ModalContent>
            <CloseButton onClick={handleCloseDeleteModal}>&times;</CloseButton>
            <p>Are you sure you want to delete the shelf "{shelfToDelete}"?</p>
            <ModalButton onClick={handleConfirmDelete}>Confirm</ModalButton>
            <ModalButton onClick={handleCloseDeleteModal}>Cancel</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      <VerticalTabContainer collapsed={isCollapsed}>
        <div onClick={() => setIsCollapsed(!isCollapsed)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
          <BsArrowsCollapseVertical color="white" />
        </div>
        {/* Gallery Tab */}
        {!isCollapsed && (
          <>
            <VerticalTab
              key="gallery"
              isActive={selectedShelf === 'fits'}
              onClick={() => handleTabClick('fits')}
              collapsed={isCollapsed}
            >
              Gallery
            </VerticalTab>

            <VerticalTab
              key="all"
              isActive={selectedShelf === null}
              onClick={() => handleTabClick(null)}
            >
              All
            </VerticalTab>

            {shelves.filter(shelfId => shelfId !== 'fits').map((shelfId) => (
              <VerticalTab
                key={shelfId}
                isActive={shelfId === selectedShelf}
                onClick={() => handleTabClick(shelfId)}
                onDoubleClick={() => handleDoubleClick(shelfId)}
              >
                {editingShelf === shelfId && !isVisitor ? (
                  <input
                    type="text"
                    value={editingShelfName}
                    onChange={(e) => setEditingShelfName(e.target.value)}
                    onBlur={handleRenameShelf}
                    onKeyDown={(e) => e.key === 'Enter' && handleRenameShelf()}
                    autoFocus
                  />
                ) : (
                  <>
                    {shelfId}
                    {!isVisitor && <DeleteIcon className="delete-icon" onClick={() => handleDeleteShelf(shelfId)}>
                      <PiTrashSimpleDuotone color="black" />
                    </DeleteIcon>}
                  </>
                )}
              </VerticalTab>
            ))}
          </>
        )}
        {/* Add New Shelf */}
        {!isVisitor && !isCollapsed && (
          <AddShelfButton onClick={handleAddShelf}>+ Add</AddShelfButton>
        )}
      </VerticalTabContainer>
    </>
  );
};

export default InventoryTabs;
