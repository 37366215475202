import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL);

const Mixpanel = {
  track: (event, properties) => {
    mixpanel.track(event, properties);
  },
  identify: (userEmail) => {
    mixpanel.identify(userEmail);
  },
  setUserProperties: (properties) => {
    mixpanel.people.set(properties);
  },
  trackAction: (action, properties = {}) => {
    mixpanel.track(action, properties);
  }
};

export default Mixpanel;
