import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Item from './InventoryView/Item';
import { fadeIn } from '../helpers/styleUtil';
import BasicModal from '../components/Modals/BasicModal';
import { userLoading } from '../redux/actions';

const AnimatedItem = styled(Item)`
  &.animate {
    animation: ${fadeIn} 0.5s ease-out forwards;
  }
`;

const ItemsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.isMobileView ? '150px' : '220px'}, 1fr));
  gap: 5px;
  padding: 2px;
  padding-right: 5px;
  max-height: 85vh;
  overflow-y: auto;

   @media (max-width: 1000px) {
     max-height: 100vh;
  }
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  color: #000000;
  font-family: 'Outfit', sans-serif;
  max-width: 600px;
  padding: 20px;
  margin: auto;
`;

const ScoreContainer = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
  color: #111;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  margin-bottom: 20px;
`;

const ScoreLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

const ScoreValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000;
`;

const SummaryContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
`;

const ItemImage = styled.img`
  width: auto;
  height: 50%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const ItemName = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const InventoryItems = ({ sortedItems, animateItems, handleItemClick }) => {
  const dispatch = useDispatch();
  const visitingUserDetails = useSelector((state) => state.visitingUserDetails.isVisiting);
  const isMobileView = useSelector((state) => state.user.isMobileView);

  const [comparisonResult, setComparisonResult] = useState(null);
  const [currentItem, setCurrentItem] = useState(null)
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCompare = useCallback((item, insights) => {
    setComparisonResult(insights);
    setCurrentItem(item);
    setShowModal(true);
    dispatch(userLoading(false));
  }, [dispatch]);

  const itemList = useMemo(() => {
    const uniqueItems = new Map(); // To track unique items by their name

    return sortedItems
      .filter((item) => !(visitingUserDetails && item?.isPrivate))      // Filter out private items if it's a visiting user
      .filter((item) => {
        const lowerCaseName = item.name.toLowerCase(); // Normalize the name for case-insensitive comparison
        if (uniqueItems.has(lowerCaseName)) {
          return false; // If an item with the same name already exists, filter it out
        } else {
          uniqueItems.set(lowerCaseName, true); // Otherwise, mark it as seen
          return true;
        }
      })
      .sort((a, b) => {
        if (a.status === 'Archive' && b.status !== 'Archive') return 1;  // Move Archive to bottom
        if (a.status !== 'Archive' && b.status === 'Archive') return -1; // Keep other items at the top
        return 0; // Keep other items unchanged
      })
      .map((item) => (
        <AnimatedItem
          handleItemClick={() => handleItemClick(item)}
          className={animateItems ? 'animate' : ''}
          key={item.id}
          item={item}
          numberOfItems={sortedItems.length}
          onCompare={handleCompare}
        />
      ));
  }, [sortedItems, animateItems, handleItemClick, visitingUserDetails]);

  return (
    <ItemsListWrapper isMobileView={isMobileView}>
      {itemList.length > 0 ? itemList : <p>No items found.</p>}
      {showModal && (
        <BasicModal onClose={closeModal}>
          {comparisonResult && (
            <ModalContent>
              <ItemName>{currentItem.name}</ItemName>
              {currentItem.images && currentItem.images[0] && (
                <ItemImage src={currentItem.images[0]} alt={currentItem.name} />
              )}
              <ScoreContainer>
                <div>
                  <ScoreLabel>Uniqueness</ScoreLabel>
                  <ScoreValue>{comparisonResult.uniquenessScore}/8</ScoreValue>
                </div>
                <div>
                  <ScoreLabel>Timelessness</ScoreLabel>
                  <ScoreValue>{comparisonResult.timelessScore}/8</ScoreValue>
                </div>
                <div>
                  <ScoreLabel>Functionality</ScoreLabel>
                  <ScoreValue>{comparisonResult.functionalityScore}/8</ScoreValue>
                </div>
                <div>
                  <ScoreLabel>Value</ScoreLabel>
                  <ScoreValue>{comparisonResult.valueScore}/8</ScoreValue>
                </div>
              </ScoreContainer>
              <SummaryContainer>{comparisonResult.productSummary}</SummaryContainer>
            </ModalContent>
          )}
        </BasicModal>
      )}
    </ItemsListWrapper>
  );
};

export default InventoryItems;
