import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverUrl } from '../../constants/urls';

// Styled components
const FooterContainer = styled.footer`
  background-color: #192a2a;
  color: white;
  text-align: center;
  padding: 8px 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterSection = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2%;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 8px;
`;

const FooterLink = styled.button`
  font-family: "Outfit", sans-serif;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 1px;
  &:hover {
    color: #666;
  }
`;

const ShareItems = styled.button`
  font-family: "Outfit", sans-serif;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 1px;
  &:hover {
    color: #666;
  }
`;

const FooterBar = () => {
  const navigate = useNavigate();
  const userDetails = useSelector(state => state.user);
  const visitingUserDetails = useSelector(state => state.visitingUserDetails.isVisiting);
  const isLoggedIn = Boolean(userDetails.email);
  const location = useLocation();
  const isLandingPage = location.pathname === '/' && !isLoggedIn;

  const linkData = [
    { label: 'Home', path: '/', allowed: true },
    { label: 'Data', path: '/data', allowed: false },
    { label: 'Discover', path: '/discover', allowed: true },
    { label: 'About', path: '/about', allowed: true },
    { label: 'Settings', path: '/settings', allowed: false },
    { label: 'Logout', path: '/logout', allowed: true }
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleShare = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/share`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userDetails.token}`
        },
        body: JSON.stringify({ email: userDetails.email })
      });
      const data = await response.json();
      if (response.ok) {
        navigator.clipboard.writeText(data.shareableLink);
        toast.success('Link copied to clipboard!');
      } else {
        throw new Error('Failed to generate share link');
      }
    } catch (error) {
      console.error('Failed to generate share link:', error);
      toast.error('Failed to generate share link');
    }
  };

  if (isLandingPage) {
    return null;
  }

  return (
    <FooterContainer>
      <FooterSection>
        {isLoggedIn && !visitingUserDetails && <ShareItems onClick={handleShare}>Share</ShareItems>}
      </FooterSection>
      <FooterSection>
        <FooterLinks>
          {linkData.map(link => (
            (!isLoggedIn && !visitingUserDetails && link.path !== '/' && link.path !== '/about' && link.path !== '/discover') ||
              (visitingUserDetails) ? null : (
              <FooterLink
                key={link.path}
                onClick={() => handleNavigation(link.path)}
              >
                {link.label}
              </FooterLink>
            )
          ))}
        </FooterLinks>
      </FooterSection>
    </FooterContainer>
  );
};

export default FooterBar;
