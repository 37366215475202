import React, { useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";

import { serverUrl } from '../../constants/urls';
import { setUploadType, userLoading, addItem, updateItem } from '../../redux/actions';
import { fadeIn } from '../../helpers/styleUtil';
import lekondoImg from '../../assets/img/lekondo.png';
import { appUrl } from '../../constants/urls';

const LekondoImage = styled.img`
  max-width: 200px;
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 20px;
  margin-top: 20px
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 0.6s ease-out forwards;
`;


const MobileView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(state => state.user);

  return (
    <MobileContainer>
      <LekondoImage src={lekondoImg} alt="Loading Image" />
      <div>
        Namaste, <span>{userDetails.name}</span>
      </div>
    </MobileContainer>
  );
};

export default MobileView;
