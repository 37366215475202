import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../redux/actions';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('logging out');
    googleLogout();
    toast.success('Logged out');
    dispatch(clearUser());
    navigate('/');
  }, [dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default LogoutPage;
