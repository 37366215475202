// src/redux/actions/discoveryActions.js

import { serverUrl } from '../../constants/urls';

export const FETCH_DISCOVERY_USERS_REQUEST = 'FETCH_DISCOVERY_USERS_REQUEST';
export const FETCH_DISCOVERY_USERS_SUCCESS = 'FETCH_DISCOVERY_USERS_SUCCESS';
export const FETCH_DISCOVERY_USERS_FAILURE = 'FETCH_DISCOVERY_USERS_FAILURE';
export const ADD_COMMENT = 'ADD_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ADD_LIKE = 'ADD_LIKE';
export const REMOVE_LIKE = 'REMOVE_LIKE';

export const addLike = (fitOwnerEmail, fitId, userEmail, author) => ({
  type: ADD_LIKE,
  payload: { fitOwnerEmail, fitId, userEmail, author }
});

export const removeLike = (fitOwnerEmail, fitId, userEmail) => ({
  type: REMOVE_LIKE,
  payload: { fitOwnerEmail, fitId, userEmail }
});

export const fetchDiscoveryUsersRequest = () => ({
  type: FETCH_DISCOVERY_USERS_REQUEST
});

export const fetchDiscoveryUsersSuccess = (users) => ({
  type: FETCH_DISCOVERY_USERS_SUCCESS,
  payload: users
});

export const fetchDiscoveryUsersFailure = (error) => ({
  type: FETCH_DISCOVERY_USERS_FAILURE,
  payload: error
});

export const addComment = (fitId, fitOwnerEmail, comment) => ({
  type: ADD_COMMENT,
  payload: { fitId, fitOwnerEmail, comment }
});

export const editComment = (fitId, fitOwnerEmail, index, newText) => ({
  type: EDIT_COMMENT,
  payload: { fitId, fitOwnerEmail, index, newText }
});

export const deleteComment = (fitId, fitOwnerEmail, index) => ({
  type: DELETE_COMMENT,
  payload: { fitId, fitOwnerEmail, index }
});

export const fetchDiscoveryUsers = (page = 1) => async dispatch => {
  dispatch(fetchDiscoveryUsersRequest());
  try {
    const response = await fetch(`${serverUrl}/api/discover?page=${page}&limit=100`);
    const data = await response.json();

    dispatch({
      type: 'FETCH_DISCOVERY_USERS_SUCCESS',
      payload: data,
      page
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_DISCOVERY_USERS_FAILURE',
      error: error.message
    });
  }
};
