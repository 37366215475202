import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import DoneImage from '../../assets/img/monad.png';
import LandingImage from '../../assets/img/landing.jpg';
import { serverUrl } from '../../constants/urls';

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 100%;
  height: 100vh;
  font-family: "Outfit", sans-serif;
  padding: 20px;
  overflow:auto;
  box-sizing: border-box;
`;

const ExampleImageCenter = styled.img`
  border: 1px solid black;
  border-radius: 5px;
  width: 70%;
  max-width: 600px;
  margin-bottom: 1%;

  @media (max-width: 768px) {
    width: 350px;
    max-width: 90%
  }
`;

const ThankYouImageStyled = styled.img`
  border-radius: 5px;
  width: 20%;
  margin-bottom: 2%;

  @media (max-width: 768px) {
    width: 350px;
    max-width: 90%
  }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Input = styled.input`
  font-family: "Outfit", sans-serif;
  border: none;
  border-bottom: 1px solid black;
  padding: 12px;
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #fafafa;
  text-transform: uppercase;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #000;
  }

  &::placeholder {
    color: #aaa;
    font-size: 16px;
  }
`;

const Button = styled.button`
  font-family: "Outfit", sans-serif;
  background-color: black;
  color: white;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  margin-top: 10px;
  letter-spacing: 0.05rem;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    padding: 12px 0;
    font-size: 14px;
  }
`;

const LoginContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media (max-width: 768px) {
    position: relative;
    margin-top:20px;
      bottom: 0px;
      right: 0px;
  }
`;

const BottomRight = styled.h1`
cursor: pointer;
  &:hover {
  opacity: 0.6;
  }
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const [accessCode, setAccessCode] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const validCodes = ['sacai8', 'sk8thi', 'reikaw'];

  const handleEmailSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      try {
        const response = await fetch(`${serverUrl}/api/waitlist`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setIsEmailSubmitted(true);
        } else {
          const data = await response.json();
          toast.error(data.message || 'Failed to join the waitlist');
        }
      } catch (error) {
        console.error('Error submitting email:', error);
        toast.error('An error occurred. Please try again later.');
      }
    } else {
      toast.error('Please enter a valid email');
    }
  };

  const signIn = () => {
    navigate('/login');
  };

  const handleDemo = () => {
    navigate('/shared-wardrobe?uuid=164be8b73b3a311d9038f94f0064a05f036e00b1d56f93a4b206959aacd05ea5');
  };


  return (
    <LandingContainer>
      <MainSection>
        {isEmailSubmitted ? (
          <div>
            <ThankYouImageStyled src={DoneImage} alt="Thank You" />
            <br /><br />
            You've been added to the waitlist.
            <br />
            <br />
            <span>&#xa7;</span>
            <br />
            <br />
            <a
              href="https://discord.gg/G7WK5T7Pmg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'none', fontSize: '16px', fontWeight: 'bold' }}
            >
              <span>&#123;</span>
              Join the community here
              <span>&#125;</span>
            </a>
          </div>
        ) : (
          <>
            <h3>Your invitation to the future of mindful ownership.</h3>
            <br />
            <ExampleImageCenter src={LandingImage} />
              <div>
                <Input
                  type="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button onClick={handleEmailSubmit}>Sign Up</Button>
              </div>
            
          </>
        )}
      </MainSection>

      {!isEmailSubmitted && <LoginContainer>
        <BottomRight onClick={handleDemo}>Patient Zero</BottomRight>
        <hr />
        <BottomRight onClick={signIn}>&#x28;Early&#x29; Access</BottomRight>
      </LoginContainer>
      }
    </LandingContainer>
  );
};

export default LandingPage;

// shared-wardrobe?uuid=164be8b73b3a311d9038f94f0064a05f036e00b1d56f93a4b206959aacd05ea5