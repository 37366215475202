/* eslint-disable no-case-declarations */
import { addToIndex, removeFromIndex, updateIndex, isRecentlyAdded, updateRecentlyAddedStatus } from '../../helpers/util';
const initialState = {
    currentItem: null,
    viewEditMode: false,
    itemForm: false,
    items: [],
    selectedAttributes: [],
    searchTerm: '',
    tagsIndex: {},
    brandsIndex: {},
    categoriesIndex: {},
    shelves: [{ id: 'fits' }],
    selectedShelf: null,
    uploadType: 'item'
};

const startRecentlyAddedUpdater = (dispatch) => {
    setInterval(() => {
        dispatch({ type: 'UPDATE_RECENTLY_ADDED' });
    }, 24 * 60 * 60 * 1000); // Check every 24 hours
};
const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_ITEM':
            return {
                ...state,
                currentItem: action.payload,
            };
        case 'SET_ITEMS_FROM_SERVER':
            const updatedItems = action.payload.map(item => ({
                ...item,
                recentlyAdded: isRecentlyAdded(item.listingDate)
            }));
            return {
                ...state,
                items: updatedItems
            };
        case 'VIEW_EDIT_MODE':
            return {
                ...state,
                viewEditMode: action.payload
            };
        case 'SET_COMPLETE_ITEMS':
            return {
                ...state,
                items: [...action.payload.items],
                tagsIndex: action.payload.tagsIndex,
                brandsIndex: action.payload.brandsIndex,
                categoriesIndex: action.payload.categoriesIndex
            };
        case 'ADD_ITEM': {
            const newItem = {
                ...action.payload,
                lastUpdated: new Date().getTime(),
                recentlyAdded: isRecentlyAdded(action.payload.listingDate)
            }

            if (state.selectedShelf) {
                newItem.shelves = [...(newItem.shelves || []), { id: state.selectedShelf }];
            }

            const newItems = [...state.items, newItem];

            // Update indices
            const newTagsIndex = { ...state.tagsIndex };
            const newBrandsIndex = { ...state.brandsIndex };
            const newcategoriesIndex = { ...state.categoriesIndex };

            (Array.isArray(action.payload.tags) ? action.payload.tags : []).forEach(tag => addToIndex(newTagsIndex, tag, action.payload.id));
            (Array.isArray(action.payload.brands) ? action.payload.brands : []).forEach(brand => addToIndex(newBrandsIndex, brand, action.payload.id));
            (Array.isArray(action.payload.categories) ? action.payload.categories : []).forEach(category => addToIndex(newcategoriesIndex, category, action.payload.id));

            return {
                ...state,
                items: newItems,
                tagsIndex: newTagsIndex,
                brandsIndex: newBrandsIndex,
                categoriesIndex: newcategoriesIndex
            };
        }
        case 'DELETE_ITEM': {
            const { payload: itemId } = action;

            // Find the item to be deleted
            const targetItem = state.items.find(item => item.id === itemId);

            if (!targetItem) {
                // If no target item was found, return state without changes
                return state;
            }

            // Filter out the deleted item from the items array
            const updatedItems = state.items.filter(item => item.id !== itemId);

            // Clone the indices to update them
            const updatedTagsIndex = { ...state.tagsIndex };
            const updatedBrandsIndex = { ...state.brandsIndex };
            const updatedCategoriesIndex = { ...state.categoriesIndex };

            // Remove references to the deleted item from the indices
            (targetItem.tags || []).forEach(tag => removeFromIndex(updatedTagsIndex, tag, itemId));
            (targetItem.brands || []).forEach(brand => removeFromIndex(updatedBrandsIndex, brand, itemId));
            (targetItem.categories || []).forEach(category => removeFromIndex(updatedCategoriesIndex, category, itemId));

            return {
                ...state,
                items: updatedItems,
                tagsIndex: updatedTagsIndex,
                brandsIndex: updatedBrandsIndex,
                categoriesIndex: updatedCategoriesIndex
            };
        }

        case 'UPDATE_ITEM': {
            const itemIndex = state.items.findIndex(item => {
                return item.id === action.payload.id
            });
            if (itemIndex === -1) return state;
            const existingItem = state.items[itemIndex];
            const updatedItem = { ...existingItem, ...action.payload, lastUpdated: new Date().getTime() };
            if (state.selectedShelf) {
                updatedItem.shelves = [...(updatedItem.shelves || []), { id: state.selectedShelf }];
            }

            const updatedItems = state.items.map((item, index) =>
                index === itemIndex ? { ...item, ...action.payload, lastUpdated: new Date().getTime() } : item
            );
            const newTagsIndex = { ...state.tagsIndex };
            const newBrandsIndex = { ...state.brandsIndex };
            const newcategoriesIndex = { ...state.categoriesIndex };
            updateIndex(newTagsIndex, existingItem.tags || [], action.payload.tags || [], action.payload.id);
            updateIndex(newBrandsIndex, existingItem.brands || [], action.payload.brands || [], action.payload.id);
            updateIndex(newcategoriesIndex, existingItem.categories || [], action.payload.categories || [], action.payload.id);

            return {
                ...state,
                items: updatedItems,
                tagsIndex: newTagsIndex,
                brandsIndex: newBrandsIndex,
                categoriesIndex: newcategoriesIndex
            };
        }
        case 'TOGGLE_ATTRIBUTE': {
            const attributeIndex = state.selectedAttributes.findIndex(attr =>
                attr.type === action.payload.type && attr.value === action.payload.value
            );

            let newSelectedAttributes;
            if (attributeIndex >= 0) {
                // Remove the attribute if it's already selected
                newSelectedAttributes = state.selectedAttributes.filter((_, index) => index !== attributeIndex);
            } else {
                // Add the attribute if it's not selected
                newSelectedAttributes = [...state.selectedAttributes, action.payload];
            }

            // Filter the items based on the selected attributes (brands, categories, etc.)
            const filteredItems = state.items.filter(item => {
                const matchesBrand = newSelectedAttributes.some(attr => attr.type === 'brand' && item.brands.includes(attr.value));
                const matchesCategory = newSelectedAttributes.some(attr => attr.type === 'category' && item.categories.includes(attr.value));

                // Ensure that the item matches at least one selected attribute
                return matchesBrand || matchesCategory || newSelectedAttributes.length === 0;
            });

            return {
                ...state,
                selectedAttributes: newSelectedAttributes,
                filteredItems: filteredItems, // Add filteredItems to the state to hold filtered results
            };
        }
        case 'TOGGLE_ITEM_FORM':
            return {
                ...state,
                isItemFormOpen: action.payload
            };
        case 'SET_SELECTED_SHELF':
            return {
                ...state,
                selectedShelf: action.payload,
            };
        case 'ADD_SHELF': {
            const newShelves = [...state.shelves, action.payload];
            return {
                ...state,
                shelves: newShelves,
            };
        }
        case 'SET_SHELVES':
            return {
                ...state,
                shelves: action.payload,
            };
        case 'SET_UPLOAD_TYPE':
            return {
                ...state,
                uploadType: action.payload,
            };
        case 'RENAME_SHELF': {
            const { oldShelfId, newShelfId } = action.payload;

            const updatedItems = state.items.map(item => ({
                ...item,
                shelves: (item.shelves || []).map(shelf =>
                    shelf.id === oldShelfId ? { ...shelf, id: newShelfId } : shelf
                )
            }));

            // Rename the shelf in the shelves array
            const updatedShelves = state.shelves.map(shelf =>
                shelf === oldShelfId ? newShelfId : shelf
            );

            // Update selectedShelf if it's the one being renamed
            const updatedSelectedShelf =
                state.selectedShelf === oldShelfId ? newShelfId : state.selectedShelf;

            return {
                ...state,
                items: updatedItems,
                shelves: updatedShelves,
                selectedShelf: updatedSelectedShelf
            };
        }
        case 'DELETE_SHELF': {
            const updatedShelves = state.shelves.filter(shelf => shelf !== action.payload);
            const updatedItems = state.items.map(item => ({
                ...item,
                shelves: (item.shelves || []).filter(shelf => shelf.id !== action.payload)
            }));
            return {
                ...state,
                shelves: updatedShelves,
                items: updatedItems,
                selectedShelf: state.selectedShelf === action.payload ? null : state.selectedShelf
            };
        }

        case 'MOVE_SHELF':
            return {
                ...state,
                shelves: action.payload,
            };

        case 'CLEAR_ALL_ATTRIBUTES':
            return {
                ...state,
                selectedAttributes: []
            };
        case 'GPT_SEARCH_ITEMS':
            return {
                ...state,
                gptSearchItems: action.payload
            };

        case 'UPDATE_RECENTLY_ADDED': {
            const updatedItems = updateRecentlyAddedStatus(state.items);
            return {
                ...state,
                items: updatedItems
            };
        }
        case 'UPDATE_LINKED_ITEMS': {
            const { itemId, linkedItemIds } = action.payload;
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id === itemId) {
                        // Update linkedItems of the main item
                        return { ...item, linkedItems: linkedItemIds.map(id => ({ itemId: id })) };
                    } else if (linkedItemIds.includes(item.id)) {
                        // Ensure linked items contain the main item
                        if (!item.linkedItems.some(li => li.itemId === itemId)) {
                            return { ...item, linkedItems: [...item.linkedItems, { itemId }] };
                        }
                    } else if (item.linkedItems.some(li => li.itemId === itemId)) {
                        // Remove the main item from linkedItems if it is not in the new linkedItemIds
                        return { ...item, linkedItems: item.linkedItems.filter(li => li.itemId !== itemId) };
                    }
                    return item;
                }),
            };
        }
        default:
            return state;
    }
};


export const initializeRecentlyAddedUpdater = (store) => {
    startRecentlyAddedUpdater(store.dispatch);
};


export default inventoryReducer;