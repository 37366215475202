import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)({
  justifyContent: 'center',
  width: '70%', // Full width
  marginBottom: '10px',
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 14px',
  }
});


const SelectInput = ({ itemDetails, setItemDetails, options, placeholder }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItemDetails({
      ...itemDetails,
      [placeholder.toLowerCase()]: value
    });
  };

  return (
    <StyledSelect
      value={itemDetails[placeholder.toLowerCase()] || ''} // Ensure value is correctly initialized
      onChange={handleChange}
      input={<OutlinedInput label={placeholder} />}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default SelectInput;
