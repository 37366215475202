// src/components/ConfirmationScreen.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  color: #333;
  text-align: center;
`;

const MessageContainer = styled.div`
  padding: 40px;
  background: white;
  border-radius: 10px;
  animation: fadeIn 1s ease-out forwards;
`;

const ThankYouMessage = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #000;
`;

const SubMessage = styled.p`
  font-size: 18px;
  color: #666;
`;

const ConfirmationScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 1200);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <PageContainer>
      <MessageContainer>
        <ThankYouMessage>Thank you for logging in!</ThankYouMessage>
        <SubMessage>You'll be redirected shortly.</SubMessage>
      </MessageContainer>
    </PageContainer>
  );
};

export default ConfirmationScreen;
