import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../helpers/styleUtil';
import monadImg from '../../assets/img/monad.png';

const ONE_SECOND = 1000;

const StepContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LekondoImage = styled.img`
  width: 25%; // Adjust the width as necessary
  max-width: 220px;
  margin-top: 5%; // Spacing between the image and the text
`;

const WaitingScreen = ({ }) => {
  const [loadingText, setLoadingText] = useState('Account in Sync....');

  useEffect(() => {
    const textInterval = setInterval(() => {
      setLoadingText(prev => prev === 'Account in Sync....' ? 'Account in Sync...' : 'Account in Sync....');
    }, ONE_SECOND);

    return () => clearInterval(textInterval); // Cleanup interval on component unmount
  }, []);

  return (
    <StepContainer>
      <LekondoImage src={monadImg} alt="Monad Image" />
      <h2>        {loadingText}      </h2>
      <p>This might take a while.</p>
    </StepContainer>
  );
};

export default WaitingScreen;
