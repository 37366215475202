// src/components/UpgradeModal.js
import React, { useEffect } from 'react';
import styled from 'styled-components';

import BasicModal from '../Modals/BasicModal';
import { useSelector } from 'react-redux';
import monadImg from '../../assets/img/monad.png';

const LekondoImage = styled.img`
  width: 35%; // Adjust the width as necessary
  max-width: 220px;
  margin-bottom: 20px; // Spacing between the image and the text
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const ModalText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #800f0f;
  font-size: 18px;
  cursor: pointer;
  z-index: 1100;
  &:hover {
    color: black;
  }
`;

const StripeContainer = styled.div`
  margin-top: 20px;
`;

const UpgradeButton = styled.button`
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 22px;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;

  &:hover {
    background-color: black;
    color: white;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const UpgradeModal = ({ isOpen, onClose }) => {
    const userType = useSelector((state) => state.user.subscription.userType);
    const userEmail = useSelector((state) => state.user.email);

    useEffect(() => {
        if (userType === 'paid') {
            // onClose();
        }
    }, [userType, onClose]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleUpgradeClick = () => {
        const stripeUrl = `https://buy.stripe.com/14kdQW5617xOfzG144?lekondo_customer_email=${encodeURIComponent(userEmail)}`;
        window.open(stripeUrl, '_blank');
    };
    if (!isOpen) return null;

    return (
      <BasicModal onClose={onClose}>
                <LekondoImage src={monadImg} alt="Loading Image" />
                <ExitButton onClick={onClose}>✕</ExitButton>
                <ModalHeader>Upgrade to Unlock Unlimited Features</ModalHeader>
                <ModalText>
                    You've reached the limit of the free plan.
                </ModalText>
                <ModalText>
                    Upgrade now to enjoy unlimited access to all features and premium support.
                </ModalText>
                <StripeContainer>
                    <UpgradeButton onClick={handleUpgradeClick}>Begin Trial</UpgradeButton>
                </StripeContainer>
          </BasicModal>
    );
};

export default UpgradeModal;