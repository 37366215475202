import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled as muiStyled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// Styled component using Material-UI's styled utility
const ShelvesInputContainer = muiStyled('div')(({ theme }) => ({
  marginTop: theme.spacing(5)
}));

const ShelvesInput = ({ itemDetails, setItemDetails }) => {
  const shelves = useSelector((state) => state.inventory.shelves).filter(shelf => shelf !== 'fits');
  const [inputValue, setInputValue] = useState('');
  const [selectedShelves, setSelectedShelves] = useState([]);

  useEffect(() => {
    if (itemDetails.shelves && Array.isArray(itemDetails.shelves)) {
      setSelectedShelves(itemDetails.shelves.map((shelf) => shelf.id));
    }
  }, [itemDetails.shelves]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue) => {
    // Filter out "fits" if it's in the newValue array
    const filteredValue = newValue.filter(value => value !== 'fits');
    const selectedShelves = filteredValue.map((shelf) => ({ id: shelf }));
    setSelectedShelves(filteredValue);
    setItemDetails({ ...itemDetails, shelves: selectedShelves });
  };

  return (
    <ShelvesInputContainer>
      <Autocomplete
        multiple
        freeSolo
        disablePortal
        id="shelves-input"
        value={selectedShelves}
        options={shelves}
        getOptionLabel={(option) => option}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Shelves" variant="outlined" fullWidth />}
      />
    </ShelvesInputContainer>
  );
};

export default ShelvesInput;
