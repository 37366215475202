// src/components/UpgradeModal.js
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../helpers/styleUtil';

const Overlay = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
  max-height: 1000px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`;

const OverlayClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  z-index: 50;
  pointer-events: none;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #800f0f;
  font-size: 18px;
  cursor: pointer;
  z-index: 1100;
  &:hover {
    color: black;
  }
`;


const BasicModal = ({ onClose, children }) => {
  const modalRef = useRef(null);
  const [outsideClicks, setOutsideClicks] = useState(0);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOutsideClicks((prev) => prev + 1);
      if (outsideClicks >= 1) {
        onClose();
      }
    } else {
      setOutsideClicks(0);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [outsideClicks]);

  return (
    <Overlay>
      {outsideClicks >= 1 && <OverlayClose>Click Again to Close</OverlayClose>}
      <ExitButton onClick={onClose}>✕</ExitButton>
      <ModalContainer ref={modalRef}>
        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default BasicModal;