import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setViewEditMode, toggleItemForm, setCurrentItem, setShelves, setCompleteItems, setVisitingUserDetails } from '../../redux/actions';
import { serverUrl } from '../../constants/urls';
import WardrobeSummary from '../../components/WardrobeSummary';
import Inventory from '../../components/Inventory';
import TagsDisplay from '../../components/TagsDisplay';
import UserDisplay from '../../components/UserDisplay';
import Loading from '../../components/Loading/Loading';
import styled from 'styled-components';

const InventoryContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 70%;
`;

const TrendsContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 30%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  overflow: hidden;

  @media (max-width: 800px) {
    flex: 1 1 100%;
    height: auto;
    margin: 10px 0;
  }

  & hr {
    border: 0;
    height: 1px;
    background-color: #ccc;
    width: 100%;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  border-top: 1px dotted #000;
  background-color: black;
  margin: 1%;
  height: 92vh;

  @media (max-width: 800px) {
    display: none;
  }
`;

const SharedWardrobePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const emailUuid = query.get('uuid');
  const [loading, setLoading] = useState(true);
  const isMobileView = useSelector(state => state.user.isMobileView);

  const itemId = query.get('itemId');
  const fetchUrl = itemId
    ? `${serverUrl}/api/shared-wardrobe?uuid=${emailUuid}&itemId=${itemId}`
    : `${serverUrl}/api/shared-wardrobe?uuid=${emailUuid}`;

  useEffect(() => {
    if (emailUuid) {
      setLoading(true);
      fetch(fetchUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('UUID validation failed');
          }
          return response.json();
        })
        .then(data => {
          if (data.visitingUserDetails) {
            dispatch(setVisitingUserDetails(data.visitingUserDetails));
            dispatch(setCompleteItems(data.visitingUserDetails.items));
            dispatch(setShelves(data.visitingUserDetails.shelves));
            if (itemId && data.visitingUserDetails.specificItem) {
              dispatch(setCurrentItem(data.visitingUserDetails.specificItem));
              dispatch(toggleItemForm(true));
              dispatch(setViewEditMode(false));
            }
            setLoading(false);
          } else {
            toast.error('Link Failed: No items found');
            throw new Error('No items found');
          }
        })
        .catch(error => {
          console.error('Error loading shared items:', error);
          console.log(error);
          toast.error('Error loading shared items');
          navigate('/discover'); // Redirect to root if the UUID is invalid or fetching fails
          setLoading(false);
        });
    }
  }, [dispatch, emailUuid]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {!isMobileView && (
        <TrendsContainer>
          <WardrobeSummary />
          <hr />
          <TagsDisplay />
          <UserDisplay />
        </TrendsContainer>
      )}
      {!isMobileView && <VerticalLine />}
      <InventoryContainer>
        <Inventory />
      </InventoryContainer>
    </>
  );
};

export default SharedWardrobePage;
