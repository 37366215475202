import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Mixpanel from '../../helpers/mixpanel'; // Import Mixpanel

import { addItem, userLoading } from '../../redux/actions';
import { serverUrl } from '../../constants/urls';
import { fadeIn } from '../../helpers/styleUtil';

const Overlay = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 50px;
  margin: 10px;
  border-radius: 15px;
  width: 70%;
  max-width: 900px;
  min-height: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 15px;
    width: 90%;
    max-width: 400px;
    min-height: auto;
    max-height: 90vh;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 180px;
  max-height: 350px;
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 200px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
`;

const Button = styled.button`
  font-family: "Outfit", sans-serif;
  padding: 10px 40px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
  margin-top: 20px;

  &:hover {
    background-color: #444;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 16px;
  }
`;

const ExitButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100;

  &:hover {
    color: #444;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;


const HiddenFileInput = styled.input`
  display: none;
`;


const OverlayClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  z-index: 50;
  pointer-events: none;
`;


const UploadModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const { email, isMobileView } = useSelector(state => state.user);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [outsideClicks, setOutsideClicks] = useState(0);
    const [photoType, setPhotoType] = useState('item');

    const modalRef = useRef(null);

    const fitpic = "https://storage.googleapis.com/bucket-lekondo/jacqchao1@gmail.com/resized-1719364041180_IMG_6036.JPG";
    const itemImage = "https://yumeyume.eu/cdn/shop/products/6130c033f2118d73549d6a92_0555.jpg?v=1650833253";

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        uploadItem(e.target.files[0]);
        e.target.value = ''; // Reset the input value
    };

    const uploadItem = async (file) => {
        if (!file) {
            toast.error("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('photos', file);
        formData.append('email', email);
        formData.append('photoType', photoType);

        dispatch(userLoading(true));
        try {
            const response = await fetch(`${serverUrl}/api/uploadImagesGPT`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                const items = data.items;
                for (const item of items) {
                    const newItem = item.newItem;
                    dispatch(addItem(newItem));
                }
                dispatch(userLoading(false));
                if (photoType === 'fits') {
                    Mixpanel.track('Fit Pic Uploaded', {
                        userEmail: email,
                        itemId: items.map(i => i.newItem.id)
                    });
                } else {
                    Mixpanel.track('Item Photo Uploaded', {
                        userEmail: email,
                        itemId: items.map(i => i.newItem.id)
                    });
                }
            } else {
                dispatch(userLoading(false));
                console.error('Failed to upload images');
                Mixpanel.track('Image Upload Failed', {
                    userEmail: email,
                    error: 'Failed to upload images'
                });
            }
        } catch (error) {
            dispatch(userLoading(false));
            console.error('Error uploading images:', error);
            Mixpanel.track('Image Upload Failed', {
                userEmail: email,
                error: error.message
            });
        }
    };

    const triggerFileInput = () => {
        setPhotoType('item')
        fileInputRef.current.click();
    };

    const triggerFileInputForFitPic = () => {
        setPhotoType('fits')
        fileInputRef.current.click();
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setOutsideClicks((prev) => prev + 1);
            if (outsideClicks >= 1) {
                onClose();
            }
        } else {
            setOutsideClicks(0);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [outsideClicks]);

    return (
        <Overlay>
            {outsideClicks >= 1 && <OverlayClose>Click Again to Close</OverlayClose>}
            <ModalContainer ref={modalRef}>
                <ExitButton onClick={onClose}>✕</ExitButton>
                <SectionWrapper>
                    <Section>
                        <h2>Share an Aesthetic</h2>
                        {!isMobileView && <ImageContainer>
                            <Image src={fitpic} alt="Example Fitpic" />
                        </ImageContainer>
                        }
                        <Button onClick={triggerFileInputForFitPic}>
                            Upload Outfit
                        </Button>
                    </Section>
                    <Section>
                        <h2>Build your Collection</h2>
                        {!isMobileView && <ImageContainer>
                            <Image src={itemImage} alt="Example Item" />
                        </ImageContainer>}
                        <Button onClick={triggerFileInput}>
                            Upload Item(s)
                        </Button>
                    </Section>
                </SectionWrapper>
                <HiddenFileInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </ModalContainer>
        </Overlay>
    );
};

export default UploadModal;
