import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { setUser, setCompleteItems, setShelves } from '../../redux/actions';
import Mixpanel from '../../helpers/mixpanel';
import { serverUrl } from '../../constants/urls';

const currentTime = new Date().toISOString();

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signIn = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const serverResponse = await fetch(`${serverUrl}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: response.code }),
        });

        const data = await serverResponse.json();

        if (serverResponse.ok) {
          dispatch(setUser(data));
          dispatch(setCompleteItems(data.items));
          dispatch(setShelves(data.shelves));
          localStorage.setItem('loginTimestamp', currentTime);

          Mixpanel.identify(data.email);
          Mixpanel.setUserProperties({
            '$name': data.name,
            '$email': data.email
          });

          Mixpanel.track('Login Successful', {
            userEmail: data.email,
          });

          toast.success('Logged in');
          navigate('/confirmation-screen');
        } else if (serverResponse.status === 404) {
          // Handle case where user doesn't exist
          toast.error('User not valid');
          navigate('/');
        } else {
          throw new Error('Failed to log in');
        }
      } catch (error) {
        console.error('Error during code exchange:', error);
        Mixpanel.track('Login Failed', {
          error: error.message,
        });
        toast.error('Login Failed');
        navigate('/'); // Redirect to home on error
      }
    },
    onError: () => {
      toast.error('Login Failed');
      navigate('/'); // Redirect to home on error
    },
    flow: 'auth-code',
    scope: 'openid email profile',
  });

  useEffect(() => {
    console.log('logging in');
    const timeoutId = setTimeout(() => {
      navigate('/');
    }, 1);

    signIn();

    return () => clearTimeout(timeoutId);
  }, [signIn, navigate]);

  return <div></div>;
};

export default LoginPage;
