import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { setShelves, setCompleteItems, clearVisitingUserDetails } from '../redux/actions';

const UserDisplayContainer = styled.div`
  font-size: 16px;
  height: 150px;
  margin-top: auto;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 35px;
  position: relative;

  hr {
    margin-bottom: 1em;
  }
`;

const UserBox = styled.div`
  font-size: 23px;
  padding: 5px 10px;
  margin: 5px;
  padding-top: 20px;
  padding-bottom: 10%;
  cursor: pointer;
  display: inline-block;

  &:hover .logout {
    display: inline;
    opacity: 0.6;
  }
`;

const LogoutButton = styled.span`
  display: none;
  margin-left: 10px;
  color: #fd5c63;
  cursor: pointer;
`;

const LogoutVisitorButton = styled.span`
  margin-left: 10px;
  color: #fd5c63;
  cursor: pointer;
`;

const UserDisplay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const userDetails = useSelector(state => state.user);
  const storedUserItems = useSelector(state => state.user.items);
  const storedUserShelves = useSelector(state => state.user.shelves);
  const [isVisitor, setIsVisitor] = useState(false);

  useEffect(() => {
    if (visitingUserDetails.isVisiting) {
      setIsVisitor(true);
    } else if (userDetails.email) {
      setIsVisitor(false);
    }
  }, [visitingUserDetails, userDetails]);


  const handleLogout = () => {
    if (isVisitor) {
      toast.success('Exited');
      dispatch(setCompleteItems(storedUserItems));
      dispatch(setShelves(storedUserShelves));
      dispatch(clearVisitingUserDetails());
      navigate('/');
    } else {
      navigate('/logout');
    }
  };

  const renderUserView = () => {
    if (isVisitor) {
      return (
        <UserBox>
          <div>
            <span>You are visiting {visitingUserDetails?.name}&apos;s collection</span>
            <LogoutVisitorButton className="logout" onClick={handleLogout}> (Exit)</LogoutVisitorButton>
          </div>
        </UserBox>
      );
    } else {
      return (
        <UserBox>
          <div>
            Namaste, <span>{userDetails.name}</span>
            <LogoutButton className="logout" onClick={handleLogout}> (Logout)</LogoutButton>
          </div>
        </UserBox>
      );
    }
  };

  return (
    <UserDisplayContainer>
      <hr />
      {renderUserView()}
    </UserDisplayContainer>
  );
};

export default UserDisplay;
