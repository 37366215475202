import React, { memo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { fadeIn } from '../../../helpers/styleUtil';

const Card = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s;
  cursor: pointer;
  width: 80%;
  &:hover {
    box-shadow: 0 4px 81px rgba(0, 0, 0, 0.1);
  }
    
`;

const ItemCount = styled.div`
  font-size: 13px;
  color: #555;
  float: center;
  margin-bottom: 10px;
  margin-top: 5px;
  gap: 10px;
`;

const ColorTile = styled.div`
  width: 2%;
  height: 100%;
  background-color: ${({ color }) => color || 'lightgray'};
  border-radius: 5px;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-left: 20px;
  min-height: 150px;
`;

const UserName = styled.h3`
  margin: 0 0 8px 0;
  font-size: 1.25em;
`;

const Summary = styled.p`
  margin: 0 0 16px 0;
  font-size: 0.875em;
  color: #555;
    text-align: center;
`;

const ItemPreview = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
`;

const UserCard = memo(({ user }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/shared-wardrobe?uuid=${user.emailUuid}`);
  };

  return (
    <Card onClick={handleCardClick}>
      <ColorTile color={user?.vibe?.colorVibe} />
      <Content>
        <UserName>{user.name}</UserName>
        <ItemCount> Items: {user.itemCount} </ItemCount>
        <Summary>{user?.vibe?.summaryShort}</Summary>
        <ItemPreview>
          {user.previewItems.map((item, index) => (
            <LazyImage key={index} src={item.imageUrl} alt={`Item ${index + 1}`} />
          ))}
        </ItemPreview>
      </Content>
    </Card>
  );
});

const LazyImage = ({ src, alt }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  });

  return (
    <div ref={ref} style={{ width: '60px', height: '60px' }}>
      {inView && <ItemImage src={src} alt={alt} loading="lazy" />}
    </div>
  );
};

export default UserCard;
