/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../helpers/styleUtil';

const Container = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  padding: 10px;
  margin-bottom: 2%;
  font-size: 18px;
  line-height: 1.6;
  overflow-y: auto;
  width: 100%;
  height: 90vh;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Lekondo Privacy Policy</h1>
      <p>We collect certain information when you use our Service, including:</p>
      <ul>
        <li>Information you provide to us, such as when you create an account, submit user content, or contact us for support.</li>
        <li>Automatically collected information, such as your IP address, device information, and usage data when you access or use our Service.</li>
        <li>Cookies and similar technologies to collect information about your interactions with our Service.</li>
        <li>Google user data, including your email address and past purchase information, with your consent, to create item listings from your purchase history.</li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>Providing, maintaining, and improving our Service.</li>
        <li>Communicating with you, including responding to your inquiries and providing customer support.</li>
        <li>Complying with legal obligations and enforcing our terms and policies.</li>
        <li>Searching through your email (with your consent) to determine which items you have bought before and creating item listings from your past purchases. We only retain your email address and profile for this purpose.</li>
      </ul>
      <h2>Information Sharing</h2>
      <p>We may share your information with third parties for various purposes such as:</p>
      <ul>
        <li>Service providers who assist us in operating our Service or conducting our business.</li>
        <li>Third parties with whom you authorize us to share your information.</li>
        <li>Law enforcement or government agencies in response to legal requests or as necessary for legal compliance.</li>
        <li>Other parties in connection with a corporate transaction, such as a merger or acquisition.</li>
      </ul>
      <h2>Data Retention and Deletion</h2>
      <p>We retain your email address and profile for the purpose of creating item listings from your past purchases. We do not retain any other Google user data. You may request deletion of your data by contacting us, and we will comply with your request in accordance with applicable laws.</p>
      <h2>Your Choices</h2>
      <p>You have certain choices regarding the information we collect and how it's used, including:</p>
      <ul>
        <li>Updating or correcting your information by accessing your account settings.</li>
        <li>Contacting us if you have any questions or concerns about your privacy.</li>
      </ul>
      <h2>Data Security</h2>
      <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
      <h2>Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Website. You are advised to review this Privacy Policy periodically for any changes.</p>
    </Container>
  );
};

export default PrivacyPolicy;
