export function filterItems(items, selectedAttributes, tagsIndex, brandsIndex, categoriesIndex) {
  const indexMap = { tag: tagsIndex, brand: brandsIndex, category: categoriesIndex };
  const itemIds = selectedAttributes.reduce((acc, attr) => {
    const ids = indexMap[attr.type][attr.value] || [];
    return acc.concat(ids);
  }, []);

  const uniqueItemIds = [...new Set(itemIds)];
  return items.filter(item => uniqueItemIds.includes(item.id));
}

export function filterItemsByAttributesAndCategories(items, tagsIndex, brandsIndex, categoriesIndex, selectedAttributes) {
  // Construct an array of selected attribute indices (tags, brands, and categories)
  const attributeIndices = selectedAttributes.map(attr => {
    if (attr.type === 'tag') return tagsIndex[attr.value] || [];
    if (attr.type === 'brand') return brandsIndex[attr.value] || [];
    if (attr.type === 'category') return categoriesIndex[attr.value] || [];
    return [];
  });

  // Flatten and deduplicate the item ids from attribute indices
  const attributeItemIds = [...new Set(attributeIndices.flat())];

  // Filter items that match the selected attributes
  let filteredByAttributes = items;


  if (attributeItemIds.length > 0) {
    filteredByAttributes = filteredByAttributes.filter(item => attributeItemIds.includes(item.id));
  }

  return filteredByAttributes;
}
// Adds item to index
export function addToIndex(index, key, value) {
  if (index[key]) {
    index[key] = [...index[key], value]; // Ensure no direct mutation
  } else {
    index[key] = [value];
  }
}


// Removes item from index
export function removeFromIndex(index, key, itemId) {
  if (!index[key]) {
    return;
  }
  index[key] = index[key].filter(id => id !== itemId);
  if (index[key].length === 0) {
    delete index[key];
  }
};

// Updates index during item update
export function updateIndex(index, oldValues, newValues, itemId) {
  oldValues.forEach(value => removeFromIndex(index, value, itemId));
  newValues.forEach(value => addToIndex(index, value, itemId));
}

export const formatDate = (date) => {
  const options = { month: 'long', day: 'numeric', weekday: 'long', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  // Replace 'day' with its ordinal counterpart
  return formattedDate.replace(/(\d+)(,)/, (match, day) => {
    const suffix = ['th', 'st', 'nd', 'rd'][(day % 10 > 3 || Math.floor(day % 100 / 10) === 1) ? 0 : day % 10];
    return day + suffix + ',';
  });
};

export const isRecentlyAdded = (listingDate) => {
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
  const now = new Date();
  const listingDateTime = new Date(listingDate).getTime();

  return now.getTime() - listingDateTime <= oneWeekInMilliseconds;
};

export const updateRecentlyAddedStatus = (items) => {
  return items.map(item => ({
    ...item,
    recentlyAdded: isRecentlyAdded(item.listingDate)
  }));
};

export function formatLocation(locationDetails) {
  const { city, state, country } = locationDetails;
  let locationString = '';

  if (city) locationString += city;
  if (state) locationString += locationString ? `, ${state}` : state;
  if (country) locationString += locationString ? `, ${country}` : country;

  return locationString;
}

export function isValidUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}