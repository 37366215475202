import React, { useState, useMemo, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled as muiStyled } from '@mui/material/styles';
import { brands } from '../../../constants/brands';

const BrandBar = muiStyled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(1)
  }
}));
export default function BrandSearch({ itemDetails, setItemDetails }) {
  const [inputValue, setInputValue] = useState('');
  const [selectedBrands, setSelectedBrands] = useState([]);

  useEffect(() => {
    if (itemDetails.brands && Array.isArray(itemDetails.brands)) {
      setSelectedBrands(itemDetails.brands);
    }
  }, [itemDetails.brands]);

  const filteredBrands = useMemo(() => {
    return brands.brands.filter(brand =>
      brand.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 3); // Limit to 3 items after filtering
  }, [inputValue]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue, reason) => {
    // Restrict the number of brands that can be selected to 3
    if (newValue.length <= 3) {
      setSelectedBrands(newValue);
      setItemDetails({ ...itemDetails, brands: newValue });
    } else if (newValue.length > 3 && reason === 'selectOption') {
      // Prevent adding more than 3 but allow removal when over limit
      setSelectedBrands(oldValue => [...oldValue.slice(0, 3)]);
    }
  };

  return (
    <BrandBar>
      <Autocomplete
        multiple
        freeSolo
        disablePortal
        id="brand-bar"
        value={selectedBrands}
        options={filteredBrands}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Brand" variant="outlined" fullWidth />}
      />
    </BrandBar>
  );
}
