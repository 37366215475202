// src/components/InventoryView/SortOptions.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { BsSortDown } from "react-icons/bs";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1.5%;
  z-index: 1;
    transition: background-color 0.3s ease, transform 0.3s ease;
      &:hover {
    transform: scale(1.1);
  }
`;

const DropdownButton = styled.button`
  background: transparent;
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
`;

const DropdownMenu = styled.ul`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 10px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 100px;
  width: 200px;  // Ensure the dropdown menu width matches the button width
  padding: 0;
  margin: 0;
  border-radius: 5px;
  list-style: none;
`;

const DropdownItem = styled.li`
  padding: 10px 30px;
  cursor: pointer;
  font-size: 15px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const sortOptions = [
  { value: 'newToOld', label: 'Sort by - New to Old' },
  { value: 'oldToNew', label: 'Sort by - Old to New' },
  { value: 'lowToHigh', label: 'Sort by - Lowest Price to Highest' },
  { value: 'highToLow', label: 'Sort by - Highest Price to Lowest' },
  { value: 'byBrand', label: 'Sort by - Brand' },
  { value: 'byCategory', label: 'Sort by - Category' }
];

const SortOptions = ({ sortOption, setSortOption }) => {
  const visitingUserDetails = useSelector(state => state.visitingUserDetails);
  const isMobileView = useSelector(state => state.user.isMobileView);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (visitingUserDetails?.email === 'bushwickorangecat@gmail.com') {
      setSortOption('highToLow');
    }
  }, [visitingUserDetails, setSortOption]);

  const handleOptionClick = (option) => {
    setSortOption(option);
    setIsOpen(false);
  };

  return !isMobileView && !visitingUserDetails?.isVisiting && (
    <DropdownContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <BsSortDown />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {sortOptions.map(option => (
          <DropdownItem key={option.value} onClick={() => handleOptionClick(option.value)}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default SortOptions;
