export const time = new Date().getTime();
import { v4 as uuidv4 } from 'uuid';

export const newItem = {
  id: uuidv4(),
  name: '',
  condition: '',
  usageFrequency: '',
  brands: [],
  color: '',
  size: '',
  images: [], // Assuming this will be handled differently if file uploads are involved
  tags: [],
  categories: [],
  listingDate: time, // For simplicity, setting upon creation,
  lastUpdated: time,
  price: 0,
  quantity: 1,
  description: '',
};

export const conditionOptions = ['New', 'Gently Used', 'Used - Good', 'Used - Fair', 'N/A'];
