import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import CollectionsView from './Collections/CollectionsView';
import FitsView from './Fits/FitsView';
import { fadeIn } from '../../helpers/styleUtil';
import LoginImage from '../../assets/img/Login-dark.png';

const PageContainer = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 95%;
  height: 90%;
`;

const Heading = styled.h1`
  font-size: 3em;
   @media (max-width: 768px) {
      display:none;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px;
`;

const Tab = styled.button`
  font-family: "Outfit", sans-serif;
  background-color: ${({ active }) => (active ? '#000' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border: 1px solid #000;
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 1.6em;

   @media (max-width: 768px) {
      margin: auto;
        margin-left: 10px;
  margin-right: 10px;
      font-size: 1em;
  }
`;


const LoginContainer = styled.div`
  z-index: 1;
  top: 20px;
  right: 20px;
  position: absolute;
  width: auto;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 20px;
        margin-bottom: 10px;
    position: relative;
    left: 0px;
  }
`;

const UserLogin = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 250px;
  height: auto;
  cursor: pointer;

  &:hover {
  opacity: 0.5;
  }
  
  @media (max-width: 768px) {
    position: relative;
    width: 220px;
    margin: 0;
  }
`;


const DiscoverPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState('Collections');
  const email = useSelector(state => state.user.email);

  const signIn = () => {
    navigate('/login');
  };

  return (
    <PageContainer>
      {!email && <LoginContainer>
        <UserLogin src={LoginImage} onClick={signIn} alt="Login" />
      </LoginContainer>}
      <Heading>Discover</Heading>
      <TabContainer>
        <Tab active={activeTab === 'Collections'} onClick={() => setActiveTab('Collections')}>Collections</Tab>
        <Tab active={activeTab === 'Gallery'} onClick={() => setActiveTab('Gallery')}>Gallery</Tab>
      </TabContainer>
      <br />
      {activeTab === 'Collections' ? (
        <CollectionsView page={page} setPage={setPage} hasMore={hasMore} setHasMore={setHasMore} />
      ) : (
        <FitsView page={page} setPage={setPage} hasMore={hasMore} setHasMore={setHasMore} />
      )}
    </PageContainer>
  );
};

export default DiscoverPage;
