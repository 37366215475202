export const categories = {
  "categories": [
    "Jackets",
    "Coats",
    "Shirts",
    "T-Shirts",
    "Sweatshirts",
    "Knitwear",
    "Pants",
    "Jeans",
    "Shorts",
    "Skirts",
    "Dresses",
    "Suits",
    "Blazers",
    "Vests",
    "Sweaters",
    "Cardigans",
    "Hoodies",
    "Tracksuits",
    "Jumpsuits",
    "Lingerie",
    "Swimwear",
    "Activewear",
    "Shoes",
    "Boots",
    "Sneakers",
    "Sandals",
    "Accessories",
    "Bags",
    "Hats",
    "Belts"
  ]
}
