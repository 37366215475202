import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isValidUrl } from '../../helpers/util';

const SearchForm = styled.form`
  width: 98%;
  padding: 3px 3px;
  margin-left: 2%;
  margin-top: 20px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const SearchInput = styled.input`
  font-family: "Outfit", sans-serif;
  width: 100%;
  padding: 20px 0px 20px 20px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #0056b3;
    outline: none;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-family: "Outfit", sans-serif;

  &:hover {
    background-color: #333;
    opacity: 0.8;
  }
`;

const Search = ({ onSubmit }) => {
  const isMobileView = useSelector(state => state.user.isMobileView);
  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isValidUrl(input)) {
      onSubmit(input, 'url'); // Pass input with 'url' type
    } else {
      onSubmit(input, 'query'); // Pass input with 'query' type
    }
  };

  return (
    <SearchForm onSubmit={handleFormSubmit}>
      <InputContainer>
        <SearchInput
          type="text"
          onChange={handleInputChange}
          value={input}
          placeholder={isMobileView ? "Search products..." : "Search products online or paste a product link..."}
        />
        {<SearchButton type="submit">Fetch</SearchButton>}
      </InputContainer>
    </SearchForm>
  );
};

export default Search;
