// Action Creators
export const setItemsFromServer = (items) => ({
  type: 'SET_ITEMS_FROM_SERVER',
  payload: items,
});

export const setCompleteItems = (items) => {
  const tagsIndex = items.reduce((index, item) => {
    item.tags.forEach(tag => {
      if (!index[tag]) {
        index[tag] = [];
      }
      index[tag].push(item.id);
    });
    return index;
  }, {});

  const brandsIndex = items.reduce((index, item) => {
    item.brands.forEach(brand => {
      if (!index[brand]) {
        index[brand] = [];
      }
      index[brand].push(item.id);
    });
    return index;
  }, {});

  const categoriesIndex = items.reduce((index, item) => {
    item.categories.forEach(category => {
      if (!index[category]) {
        index[category] = [];
      }
      index[category].push(item.id);
    });
    return index;
  }, {});

  return {
    type: 'SET_COMPLETE_ITEMS',
    payload: {
      items: [...items],
      tagsIndex,
      brandsIndex,
      categoriesIndex
    }
  };
};
export const gptSearchItems = (searchItems) => ({
  type: 'GPT_SEARCH_ITEMS',
  payload: searchItems,
});

export const addItem = (item) => {
  // Define the default item structure with blank/default values
  const defaultItem = {
    id: '',
    images: [],
    name: "",
    condition: "",
    usageFrequency: "",
    brands: [],
    color: "",
    shelves: [],
    quantity: 1,
    description: '',
    size: "",
    tags: [],
    categories: [],
    listingDate: '',
    lastUpdated: '',
    descriptors: '',
  };

  return {
    type: 'ADD_ITEM',
    payload: {
      ...defaultItem, // Start with the defaults
      ...item          // Override with actual item values if they exist
    }
  };
};

export const deleteItem = (item) => ({
  type: 'DELETE_ITEM',
  payload: item,
});

export const updateItem = (itemData) => ({
  type: 'UPDATE_ITEM',
  payload: itemData
});

export const setViewEditMode = (viewEditMode) => ({
  type: 'VIEW_EDIT_MODE',
  payload: viewEditMode
});

export const setCurrentItem = (item) => ({
  type: 'SET_CURRENT_ITEM',
  payload: item
});

export const setUploadType = (uploadType) => ({
  type: 'SET_UPLOAD_TYPE',
  payload: uploadType
});

export const selectTags = (tag) => ({
  type: 'SELECT_TAGS',
  payload: tag,
});

export const toggleAttribute = (attribute) => ({
  type: 'TOGGLE_ATTRIBUTE',
  payload: attribute
});

export const clearAllAttributes = (attribute) => ({
  type: 'CLEAR_ALL_ATTRIBUTES',
  payload: attribute
});

export const toggleItemForm = (itemForm) => ({
  type: 'TOGGLE_ITEM_FORM',
  payload: itemForm
});

export const addShelf = (shelf) => ({
  type: 'ADD_SHELF',
  payload: shelf,
});

export const setShelves = (shelves) => ({
  type: 'SET_SHELVES',
  payload: shelves,
});

export const setSelectedShelf = (shelfId) => ({
  type: 'SET_SELECTED_SHELF',
  payload: shelfId,
});

export const renameShelf = (oldShelfId, newShelfId) => ({
  type: 'RENAME_SHELF',
  payload: { oldShelfId, newShelfId }
});

export const moveShelf = (newShelves) => ({
  type: 'MOVE_SHELF',
  payload: newShelves,
});

export const deleteShelf = (shelfId) => ({
  type: 'DELETE_SHELF',
  payload: shelfId
});

export const updateLinkedItems = (itemId, linkedItemIds) => ({
  type: 'UPDATE_LINKED_ITEMS',
  payload: { itemId, linkedItemIds },
});