import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { userLoading, setSettings, updateUserSubscription } from '../../redux/actions';
import { fadeIn } from '../../helpers/styleUtil';
import { serverUrl } from '../../constants/urls';

const SettingsContainer = styled.div`
  animation: ${fadeIn} 0.6s ease-out forwards;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 600px;
  margin: 20px auto;
`;

const SettingsTitle = styled.h2`
  font-family: "Outfit", sans-serif;
`;

const SettingsOption = styled.div`
  margin: 10px 0;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SettingsLabel = styled.label`
  font-family: "Outfit", sans-serif;
`;

const SettingsSwitch = styled.input`
  cursor: pointer;
`;

const Button = styled.button`
  font-family: "Outfit", sans-serif;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background-color: #333;
  }
`;

const SettingsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const privacySetting = useSelector(state => state.user?.settings?.privacy);

  const [isPrivate, setIsPrivate] = useState(privacySetting === 'private');

  useEffect(() => {
    setIsPrivate(privacySetting === 'private');
  }, [privacySetting]);

  const handleDowngradeClick = async () => {
    dispatch(userLoading(true));
    try {
      // Update the user's subscription status in the backend
      const response = await fetch(`${serverUrl}/api/stripe/downgrade-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email })
      });

      console.log(response);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(userLoading(false));
      dispatch(updateUserSubscription({ ...user.subscription, userType: 'free' }));
      toast.success('Downgraded successfully');
    } catch (error) {
      console.error('Error downgrading subscription:', error);
    }
  };

  const handleUpgradeClick = () => {
    const stripeUrl = `https://buy.stripe.com/14kdQW5617xOfzG144?lekondo_customer_email=${encodeURIComponent(user.email)}`;
    window.open(stripeUrl, '_blank');
  };

  const handlePublicChange = (e) => {
    const newPrivacySetting = e.target.checked ? 'private' : 'public';
    setIsPrivate(e.target.checked);
    dispatch(setSettings({ privacy: newPrivacySetting }));
  };

  return (
    <SettingsContainer>
      <SettingsTitle>Settings</SettingsTitle>
      <SettingsOption>
        <SettingsLabel>Make Wardrobe Private</SettingsLabel>
        <SettingsSwitch
          type="checkbox"
          checked={isPrivate}
          onChange={handlePublicChange}
        />
      </SettingsOption>
    </SettingsContainer>
  );
};

export default SettingsPage;

/*

Hide for now
      <SettingsOption>
        <SettingsLabel>Subscription</SettingsLabel>
        {user.subscription.userType === 'free' ? (
          <Button onClick={handleUpgradeClick}>Upgrade to Paid</Button>
        ) : (
          <Button onClick={handleDowngradeClick}>Downgrade to Free</Button>
        )}
      </SettingsOption>
*/
