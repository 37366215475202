// utils/loadState.js

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('appState');
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    if (serializedState === null || loginTimestamp === null) {
      return undefined; // No state in local storage, return undefined to use initial state
    }
    const currentTime = new Date();
    const savedTime = new Date(loginTimestamp);
    const timeDifference = currentTime - savedTime;

    const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

    if (timeDifference > twelveHours) {
      localStorage.clear();
      return undefined; // Clear state and prompt for login if more than 48 hours have passed
    }

    // Update the login timestamp to extend the expiration period
    localStorage.setItem('loginTimestamp', currentTime.toISOString());

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
