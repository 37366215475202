import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAttribute, clearAllAttributes } from '../redux/actions';
import styled, { css } from 'styled-components';
import { fadeIn } from '../helpers/styleUtil';

const OntologyContainer = styled.div`
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 52vh;
`;
const TagsContainer = styled.div`
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SortOptionsContainer = styled.div`
  margin-top: 10%;
  align-self: flex-end;
`;

const SortOptions = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  font-family: "Outfit", sans-serif;
  &:focus {
    border-color: #5F5449;
    outline: none;
  }
`;

const TagRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SpecialRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
    justify-content: left;
    padding: 10px;
`;

const Tag = styled.div`
  font-size: 13px;
  padding: 10px 20px;
  background-color: #e3dbc8;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #cfd8dc;
    transform: scale(1.01);
  }

  &.selected {
    padding: 7px 17px;
    border: 2px dotted black;
    background-color: white;
    opacity: 1;
    color: black;
    font-size: 17px;
    animation: ${fadeIn} 0.2s ease-out forwards;
  }

  ${(props) =>
    props.special === 'Wishlist' &&
    css`
      font-family: "Outfit", sans-serif;
      background-color: #125c25;
      color: white;
          border-radius: 8px;
          padding: 10px 20px;
    `}

  ${(props) =>
    props.special === 'Kondo' &&
    css`
      font-family: "Outfit", sans-serif;
      background-color: #800f0f;
      color: white;
      border-radius: 8px;
      padding: 10px 20px;
    `}

  ${(props) =>
    props.special === 'Archive' &&
    css`
        font-family: "Outfit", sans-serif;
        background-color: #7f7689;
        color: white;
        border-radius: 8px;
      `}
`;

const SpecialOption = styled(Tag)`
  background-color: #BCB88A;
`;

const Brand = styled(Tag)`
  background-color: #5F5449;
  color: white;
`;

const Category = styled(Tag)`
  background-color: #518384;
  color: white;
`;

const DeselectAllTag = styled(Tag)`
  background-color: black;
  color: white;
    justify-content: left;
`;

const RangeHeader = styled.h2`
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-family: "Outfit", sans-serif;
  color: #333;
  margin: 20px 0 10px 0;
`;


const TagsDisplay = () => {
  const dispatch = useDispatch();
  const {
    tagsIndex,
    brandsIndex,
    categoriesIndex,
    selectedAttributes,
    items
  } = useSelector(state => state.inventory);

  const [sortCriteria, setSortCriteria] = useState('brands');
  const [sortedAttributes, setSortedAttributes] = useState([]);

  useEffect(() => {
    const attributes = [
      ...Object.keys(tagsIndex || {}).map(tag => ({ type: 'tag', value: tag })),
      ...Object.keys(brandsIndex || {}).map(brand => ({ type: 'brand', value: brand })),
      ...Object.keys(categoriesIndex || {}).map(category => ({ type: 'category', value: category }))
    ].filter(attr => {
      // Filter out attributes that have no associated items
      const isTagUsed = items.some(item => (item.tags || []).includes(attr.value));
      const isBrandUsed = items.some(item => {
        return (item.brands || []).includes(attr.value)
      });

      const isCategoryUsed = items.some(item => (item.categories || []).includes(attr.value));

      if (attr.type === 'tag') return isTagUsed;
      if (attr.type === 'brand') return isBrandUsed;
      if (attr.type === 'category') return isCategoryUsed;

      return true;
    });

    const WishlistTag = attributes.find(attr => attr.value === 'Wishlist');
    const KondoTag = attributes.find(attr => attr.value === 'Kondo');
    const ArchiveTag = attributes.find(attr => attr.value === 'Archive');
    const otherAttributes = attributes.filter(attr => attr.value !== 'Wishlist' && attr.value !== 'Kondo' && attr.value !== 'Archive');

    console.log(attributes);
    const sortAttributes = (attributes) => {
      attributes.sort((a, b) => {
        if (sortCriteria === 'everything') {
          if (a.type === 'category' && b.type !== 'category') return -1;
          if (a.type !== 'category' && b.type === 'category') return 1;
          if (a.type === 'brand' && b.type !== 'brand') return -1;
          if (a.type !== 'brand' && b.type === 'brand') return 1;
          if (a.type === 'tag' && b.type !== 'tag') return -1;
          if (a.type !== 'tag' && b.type === 'tag') return 1;
          return 0;
        }
        switch (sortCriteria) {
          case 'brands':
            return a.type === 'brand' ? -1 : 1;
          case 'tags':
            return a.type === 'tag' ? -1 : 1;
          case 'categories':
            return a.type === 'category' ? -1 : 1;
          default:
            return 0;
        }
      });
    };

    sortAttributes(otherAttributes);
    setSortedAttributes([WishlistTag, KondoTag, ArchiveTag, ...otherAttributes].filter(Boolean));
  }, [tagsIndex, brandsIndex, categoriesIndex, sortCriteria, items]);

  const handleAttributeClick = (attr) => {
    dispatch(toggleAttribute(attr));
  };

  const handleDeselectAll = () => {
    dispatch(clearAllAttributes());
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const getTypeComponent = (type, value) => {
    if (type === 'tag') {
      if (value === 'Wishlist') {
        return function WishlistTag(props) {
          return <Tag {...props} special="Wishlist" />;
        };
      }
      if (value === 'Kondo') {
        return function KondoTag(props) {
          return <Tag {...props} special="Kondo" />;
        };
      }
      if (value === 'Archive') {
        return function ArchiveTag(props) {
          return <Tag {...props} special="Archive" />;
        };
      }
      return Tag;
    } else if (type === 'brand') {
      return Brand;
    } else if (type === 'category') {
      return Category;
    } else {
      return SpecialOption;
    }
  };

  const filteredAttributes = sortedAttributes.filter(attr => {
    if (sortCriteria === 'categories') return attr.type === 'category';
    if (sortCriteria === 'brands') return attr.type === 'brand';
    if (sortCriteria === 'tags') return attr.type === 'tag';
    return true;
  });

  const otherAttributes = filteredAttributes.filter(attr => attr.value !== 'Wishlist' && attr.value !== 'Kondo' && attr.value !== 'Archive');
  const WishlistTag = sortedAttributes.find(attr => attr.value === 'Wishlist');
  const KondoTag = sortedAttributes.find(attr => attr.value === 'Kondo');
  const ArchiveTag = sortedAttributes.find(attr => attr.value === 'Archive');

  // Grouping attributes by first letter range
  const groupedAttributes = {
    'A-D': [],
    'E-J': [],
    'K-O': [],
    'P-T': [],
    'U-Z': [],
    'Others': []
  };

  otherAttributes.sort((a, b) => a.value.localeCompare(b.value)).forEach(attr => {
    const firstLetter = attr.value[0].toUpperCase();
    if (firstLetter >= 'A' && firstLetter <= 'D') groupedAttributes['A-D'].push(attr);
    else if (firstLetter >= 'E' && firstLetter <= 'J') groupedAttributes['E-J'].push(attr);
    else if (firstLetter >= 'K' && firstLetter <= 'O') groupedAttributes['K-O'].push(attr);
    else if (firstLetter >= 'P' && firstLetter <= 'T') groupedAttributes['P-T'].push(attr);
    else if (firstLetter >= 'U' && firstLetter <= 'Z') groupedAttributes['U-Z'].push(attr);
    else groupedAttributes['Others'].push(attr);
  });

  return (
    <OntologyContainer>
      <SpecialRow>
        <DeselectAllTag onClick={handleDeselectAll}>
          Reset
        </DeselectAllTag>
        {ArchiveTag && (
          <Tag
            special="Archive"
            className={selectedAttributes.some(a => a.type === ArchiveTag.type && a.value === ArchiveTag.value) ? 'selected' : ''}
            onClick={() => handleAttributeClick(ArchiveTag)}
          >
            {'Archive'}
          </Tag>
        )}
        {WishlistTag && (
          <Tag
            special="Wishlist"
            className={selectedAttributes.some(a => a.type === WishlistTag.type && a.value === WishlistTag.value) ? 'selected' : ''}
            onClick={() => handleAttributeClick(WishlistTag)}
          >
            {'Saved'}
          </Tag>
        )}
        {KondoTag && (
          <Tag
            special="Kondo"
            className={selectedAttributes.some(a => a.type === KondoTag.type && a.value === KondoTag.value) ? 'selected' : ''}
            onClick={() => handleAttributeClick(KondoTag)}
          >
            {'Kondo'}
          </Tag>
        )}
      </SpecialRow>
      <TagsContainer>
        {Object.entries(groupedAttributes).map(([range, attributes], index) => (
          <React.Fragment key={index}>
            {attributes.length > 0 && <RangeHeader>{range}</RangeHeader>}
            <TagRow>
              {attributes.map((attr, idx) => {
                const Attribute = getTypeComponent(attr.type, attr.value);
                return (
                  <Attribute
                    key={idx}
                    className={selectedAttributes.some(a => a.type === attr.type && a.value === attr.value) ? 'selected' : ''}
                    onClick={() => handleAttributeClick(attr)}
                  >
                    {attr.value}
                  </Attribute>
                );
              })}
            </TagRow>
          </React.Fragment>
        ))}
      </TagsContainer>
      <SortOptionsContainer>
        <SortOptions value={sortCriteria} onChange={handleSortChange}>
          <option value="brands">Brands</option>
          <option value="categories">Categories</option>
          <option value="tags">Tags</option>
          <option value="everything">A - Z</option>
        </SortOptions>
      </SortOptionsContainer>
    </OntologyContainer>
  );
};

export default TagsDisplay;
