import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Search from '../InventoryView/Search';
import SearchResults from '../Search/SearchResults';

import { addItem, setCurrentItem, userLoading } from '../../redux/actions';
import { serverUrl } from '../../constants/urls';
import { fadeIn } from '../../helpers/styleUtil';
import { isValidUrl } from '../../helpers/util';

const StepContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepTwo = ({ nextStep }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const fetchItemDetailsFromUrl = async (url) => {
        dispatch(userLoading(true));
        try {
            const serverResponse = await fetch(`${serverUrl}/api/scrape-and-save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    linkFromWebsite: url
                })
            });

            const { scrapedItem } = await serverResponse.json();

            if (scrapedItem.id) {
                const item = { ...scrapedItem, isItemScraped: true };
                dispatch(addItem(item));
                dispatch(userLoading(false));
            }
            nextStep();
        } catch (error) {
            dispatch(userLoading(false));
            console.error('Failed to fetch item details:', error);
        }
    };
    const handleSearchSubmit = async (input) => {
        setIsSearching(true);
        dispatch(userLoading(true));
        try {
            let response;
            if (isValidUrl(input)) {
                // Create a product object from a URL
                response = await fetch(`${serverUrl}/api/scrape-and-save`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ linkFromWebsite: input })
                });
            } else {
                // Search for products
                response = await fetch(`${serverUrl}/api/queryProduct`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: input, email: user.email
                    })
                });
            }

            const data = await response.json();
            if (isValidUrl(input)) {
                // If it was a URL, we'll have a single item to add
                const item = data.scrapedItem;
                dispatch(addItem(item));
                dispatch(setCurrentItem(item));
                nextStep();
            } else {
                // If it was a search, we'll display the search results
                setSearchResults(data.productsFromQuery);
            }

            setIsSearching(false);
            dispatch(userLoading(false));
        } catch (error) {
            setIsSearching(false);
            dispatch(userLoading(false));
            console.error('Failed to fetch data:', error);
        }
    };

    const handleItemAdded = () => {
        nextStep();
    };

    const handleUrlSubmit = useCallback((url) => {
        if (url) {
            fetchItemDetailsFromUrl(url);
        }
    }, []);

    return (
        <StepContainer>
            <h2>Build Your Collection</h2>
            <Search onSubmit={handleSearchSubmit} onUrlSubmit={handleUrlSubmit} />
            {isSearching ? <p>Searching...</p> : <SearchResults results={searchResults} onItemAdded={handleItemAdded} />}
        </StepContainer>
    );
};

export default StepTwo;
