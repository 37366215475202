// src/redux/store.js
import { thunk } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import rootReducer from './reducers/rootReducer';
import { initializeRecentlyAddedUpdater } from './reducers/inventoryReducer';
import initializeSocket from './middleware/socket';
import { saveDiscoveryStateMiddleware } from './middleware/saveDiscoveryStateMiddleware';

import { saveStateMiddleware } from './middleware/saveState';
import { vibeCheckMiddleware } from './middleware/fetchVibe';

import { loadState } from './utils/loadState';

const persistedState = loadState(); // Load the state saved in local storage

const getMiddlewares = (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware().concat(thunk);

    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(reduxImmutableStateInvariant());
    }

    return middlewares.concat(vibeCheckMiddleware, saveStateMiddleware, saveDiscoveryStateMiddleware);
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState, // Use the loaded state as initial state
    middleware: getMiddlewares,
});

initializeSocket(store);
initializeRecentlyAddedUpdater(store);

export default store;
