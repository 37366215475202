import React, { useState, useMemo, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled as muiStyled } from '@mui/material/styles';
import { categories } from '../../../constants/categories';

// Styled component using Material-UI's styled utility
const CategoryBarContainer = muiStyled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  '& .MuiTextField-root': {
    height: '100px', // Adjusted to be percentage-based if needed
    marginBottom: theme.spacing(1)
  }
}));

export default function categoryBar({ itemDetails, setItemDetails }) {
  const [inputValue, setInputValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (itemDetails.categories && Array.isArray(itemDetails.categories)) {
      setSelectedCategories(itemDetails.categories);
    }
  }, [itemDetails.categories]);

  const filteredcategories = useMemo(() => {
    return categories.categories.filter(category =>
      category.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 3); // Limit to 3 items after filtering
  }, [inputValue]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };


  const handleChange = (event, newValue, reason) => {
    // Convert selected categories to lowercase
    const lowerCasedValue = newValue.map(category => category.toLowerCase());
    // Restrict the number of categories that can be selected to 3
    if (lowerCasedValue.length <= 5) {
      setSelectedCategories(lowerCasedValue);
      setItemDetails({ ...itemDetails, categories: lowerCasedValue });
    } else if (lowerCasedValue.length > 3 && reason === 'selectOption') {
      // Prevent adding more than 3 but allow removal when over limit
      setSelectedCategories(oldValue => [...oldValue.slice(0, 5)]);
    }
  };

  return (
    <CategoryBarContainer>
      <Autocomplete
        multiple
        freeSolo
        disablePortal
        id="category-bar"
        value={selectedCategories}
        options={filteredcategories}
        sx={{ width: '100%', height: '100%', maxHeight: 100, maxWidth: 360 }} // Inline style overrides
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Categories" variant="outlined" fullWidth />}
      />
    </CategoryBarContainer>
  );
}
