import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { AiTwotoneDelete } from "react-icons/ai";
import LinkedItemsInput from '../itemForm/LinkedItemsInput';
import { fadeIn } from '../../../helpers/styleUtil';

const EditContainer = styled.div`
animation: ${fadeIn} 0.6s ease-out forwards;
  margin-top: 20px;
  width: 100%;
  height: 90vh;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const ThirdWidthFormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 1px;
`;

const TextInput = styled.input`
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  max-width: 70%;
  min-height: 22px;
  border-radius: 5px;
  background: #e3e3e3;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #333;
  }
`;

const DeleteButton = styled(AiTwotoneDelete)`
  font-size: 35px;
  cursor: pointer;
  float: right;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.5;
    color: #fd5c63;
  }
`;

const ActionButton = styled.button`
font-family: "Outfit", sans-serif;
  width: 35%;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #000;
  font-size: 16px;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const LinkedItemsInputWidth = styled.div`
width: 70%;
`

const DescriptionBox = styled.textarea`
  font-size: 16px;
  width: 90%;
  max-width: 100%;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  resize: vertical;
    white-space: pre-wrap; /* Ensures that line breaks are preserved */
`;

const CheckboxInput = styled.input`
  display: flex;
  margin-right: auto;
  margin-top: 10px;
`;

const FitPicEdit = ({ itemDetails, setItemDetails, onSave, handleDelete }) => (
  <EditContainer>
    <FormRow>
      <ThirdWidthFormField>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          type="text"
          value={itemDetails.name}
          onChange={e => setItemDetails({ ...itemDetails, name: e.target.value })}
          required
        />
      </ThirdWidthFormField>
      <ThirdWidthFormField>
        <Label htmlFor="listingDate">Date</Label>
        <StyledDatePicker
          selected={itemDetails.listingDate ? new Date(itemDetails.listingDate) : null}
          onChange={date => setItemDetails({ ...itemDetails, listingDate: date.toISOString() })}
          placeholderText="Date"
          dateFormat="MMM d, yyyy"
        />
      </ThirdWidthFormField>
      <ThirdWidthFormField>
        <Label htmlFor="isPrivate">Private</Label>
        <CheckboxInput
          id="isPrivate"
          type="checkbox"
          checked={itemDetails.isPrivate || false}
          onChange={e => setItemDetails({ ...itemDetails, isPrivate: e.target.checked })}
        />
      </ThirdWidthFormField>
    </FormRow>
    <FormRow>
      <ThirdWidthFormField>
        <Label htmlFor="city">City</Label>
        <TextInput
          id="city"
          type="text"
          value={itemDetails.locationDetails?.city || ''}
          onChange={e => setItemDetails({
            ...itemDetails,
            locationDetails: { ...itemDetails.locationDetails, city: e.target.value }
          })}
          required
        />
      </ThirdWidthFormField>
      <ThirdWidthFormField>
        <Label htmlFor="state">State</Label>
        <TextInput
          id="state"
          type="text"
          value={itemDetails.locationDetails?.state || ''}
          onChange={e => setItemDetails({
            ...itemDetails,
            locationDetails: { ...itemDetails.locationDetails, state: e.target.value }
          })}
          required
        />
      </ThirdWidthFormField>
      <ThirdWidthFormField>
        <Label htmlFor="country">Country</Label>
        <TextInput
          id="country"
          type="text"
          value={itemDetails.locationDetails?.country || ''}
          onChange={e => setItemDetails({
            ...itemDetails,
            locationDetails: { ...itemDetails.locationDetails, country: e.target.value }
          })}
          required
        />
      </ThirdWidthFormField>
    </FormRow>
    <DescriptionBox
      id="description"
      value={itemDetails.description}
      onChange={e => setItemDetails({ ...itemDetails, description: e.target.value })}
    />
    <LinkedItemsInputWidth>
      <LinkedItemsInput itemDetails={itemDetails} setItemDetails={setItemDetails} />
    </LinkedItemsInputWidth>
    <DeleteButton onClick={handleDelete} />
    <ActionButton onClick={onSave}>Save</ActionButton>
  </EditContainer>
);

export default FitPicEdit;
