import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setFirstLogin } from './redux/actions';
import WardrobeSummary from './components/WardrobeSummary';
import Inventory from './components/Inventory';
import TagsDisplay from './components/TagsDisplay';
import UserDisplay from './components/UserDisplay';
import OnboardingScreen from './components/Onboarding/OnboardingScreen'

const TrendsContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 25%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  overflow: hidden;

  @media (max-width: 800px) {
    flex: 1 1 1%;
    height: auto;
    margin: 10px 0;
  }

  & hr {
    border: 0;
    height: 1px;
    background-color: #ccc;
    width: 100%;
  }
`;

const InventoryContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 75%;
  min-height: 500px
`;

const VerticalLine = styled.div`
  width: 1px;
  border-top: 1px dotted #000;
  background-color: black;
  margin: 1%;
  height: 92vh;

  @media (max-width: 800px) {
    display: none;
  }
`;


const AppContent = () => {
  const dispatch = useDispatch();
  const { isFirstLogin, isMobileView } = useSelector(state => state.user);
  const [isUserFirstTime, setUserFirstTime] = useState(isFirstLogin);

  useEffect(() => {
    setUserFirstTime(isFirstLogin);
  }, [isFirstLogin]);

  const onFinish = () => {
    setUserFirstTime(false);
    dispatch(setFirstLogin(false));
  };

  return (
    <>
      <TrendsContainer>
        {isUserFirstTime && <OnboardingScreen onFinish={onFinish} />}
        <div className="wardrobe-summary">
          {!isMobileView && <WardrobeSummary />}
          <hr />
        </div>
        <div className="tags-display">
          {!isMobileView && <TagsDisplay />}
        </div>
        {!isMobileView && <UserDisplay />}
      </TrendsContainer>
      <VerticalLine />
      <InventoryContainer>
        <Inventory />
      </InventoryContainer>
    </>
  );
};

export default AppContent;
