import { toast } from 'react-toastify';

import { serverUrl } from '../../constants/urls';
import initializeSocket from './socket';
import { userLoading, updateUserItems, updateUserShelves } from '../actions/index';

let socket;
let middlewareTriggered = false; // Flag to check if the middleware triggered the action

const reduxActionsTriggerSave = ['SET_EMAIL_SYNC_STATE', 'SET_FIRST_LOGIN', 'UPDATE_LINKED_ITEMS', 'USER_VIBE', 'RENAME_SHELF', 'DELETE_SHELF', 'ADD_SHELF', 'ADD_ITEM', 'DELETE_ITEM', 'UPDATE_ITEM', 'SET_SETTINGS', 'UPDATE_MEASUREMENTS', 'MOVE_SHELF'];

export const saveStateMiddleware = store => next => action => {
  if (!socket) {
    socket = initializeSocket(store);
  }

  if (middlewareTriggered) {
    // If the middleware triggered this action, reset the flag and continue without causing side effects
    middlewareTriggered = false;
    return next(action);
  }

  const result = next(action);
  const newState = store.getState();

  try {
    const serializedState = JSON.stringify(newState);
    localStorage.setItem('appState', serializedState);
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      console.error('Error: Local storage limit exceeded. Consider optimizing the data or using a different storage method.');
    } else {
      console.error('Error saving to local storage:', error);
    }
  }

  const isUserLoggedIn = newState.user.email && newState.visitingUserDetails.name === null;

  if (isUserLoggedIn) {
    if (reduxActionsTriggerSave.includes(action.type)) {
      middlewareTriggered = true; // Set the flag before dispatching any action that could loop back
      store.dispatch(userLoading(true));
      newState.user.items = newState.inventory.items;

      if (newState.user.shelves !== newState.inventory.shelves) {
        newState.user.shelves = [...newState.inventory.shelves];
      }
      store.dispatch(updateUserItems(newState.user.items));
      store.dispatch(updateUserShelves(newState.user.shelves));
      // newState.user.items = newState.inventory.items;

      fetch(`${serverUrl}/api/inventory/saveState`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: newState.user
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log('State saved successfully:', data);
          // Emit a WebSocket event to notify other clients
          if (socket) {
            socket.emit('stateUpdated', {
              user: newState.user
            });
          } else {
            console.error('Socket is not initialized');
            store.dispatch(userLoading(false));
          }
          store.dispatch(userLoading(false));
          toast.success('Updated successfully!');
        }).catch(error => {
          store.dispatch(userLoading(false));
          toast.error('Saved failed');
          console.log(error);
        })
        .finally(() => {
          middlewareTriggered = false; // Reset the flag after the async operation completes
        });
    }
  }

  return result;
};
